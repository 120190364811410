// components
import Rating from "../../UI/Rating/Rating";
import PriceBadge from "../../UI/Badge/PriceBadge/PriceBadge";
import CartBadge from "../../UI/Badge/CartBadge/CartBadge";
import FavouriteBadge from "../../UI/Badge/FavouriteBadge/FavouriteBadge";

// react router
import { Link } from "react-router-dom";
// classes
import classes from "./Book.module.css";
// classnames
import classNames from "classnames";

const Book = (props) => {
  // props
  const { book, hideTitle, hideAuthorName, noCart, bookDetailsPageStyle } =
    props;

  // view
  return (
    <article
      className={classNames(
        classes.Book,
        bookDetailsPageStyle && classes.bookDetailsPageStyle
      )}
    >
      <div
        className={classNames(
          classes.Book__Cover,
          bookDetailsPageStyle && classes.Book__Cover_bookDetailsPageStyle
        )}
      >
        <Link to={`/books/${book.id}`}>
          <img
            src={book.coverUrl}
            alt="book cover"
            className={classes.Book__Cover__Img}
          />
        </Link>
        {book.inSubscription === true ? (
          <span className={classes.bookcloud_label}>كلاود بلاس</span>
        ) : null}
        <span className={classes.Book__Cover__PriceBadgeWrapper}>
          <PriceBadge
            price={book.price}
            isFree={book.free}
            ISUSDPrice={book.ISUSDPrice}
          />
        </span>
        <div className={classes.Book__Cover__FooterBadges}>
          {(!noCart && book.inSubscription === false) ||
          window.localStorage.getItem("isUserSubscriped") === "false" ? (
            <span className={classes.Book__Cover__CartBadgeWrapper}>
              <CartBadge book={book} />
            </span>
          ) : book.inSubscription === true &&
            window.localStorage.getItem("isUserSubscriped") === "true" ? (
            <span className={classes.Book__Cover__FavouriteBadgeWrapper}>
              <FavouriteBadge book={book} whiteIcon={true} />
            </span>
          ) : null}
        </div>
      </div>

      {/* {!hideTitle && (
        <Link
          to={`/books/${book.id}`}
          className={classes.Book__Title}
          title={book.title}
        >
          {book.title}
        </Link>
      )}

      {!hideAuthorName && (
        <Link
          to={`/authors/${book.writerUserId}`}
          className={classes.Book__Author}
        >
          {book.writerUserName}
        </Link>
      )}
      <Rating numberOfRatings={book.numberOfRatings} rating={book.rating} /> */}
    </article>
  );
};

export default Book;
