// react
import { useEffect, useState } from "react";
// components
import BookComment from "./BookComment/BookComment";
import Info from "../../UI/Info/Info";
// css
import classes from "./BookComments.module.css";

const BookComments = (props) => {
  // props
  const { comments } = props;
  // state
  const [displayComments, setDisplayComments] = useState([]);
  const [pointer, setPointer] = useState(3);

  // handlers
  const viewMoreCommentsClickedHandler = () => {
    setPointer((prevPointer) => prevPointer + 3);
  };

  // effects
  useEffect(() => {
    setDisplayComments(comments.slice(0, pointer));
  }, [pointer, comments]);

  // view
  let content;
  if (comments.length === 0) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Info infoMessage="لا توجد تعليقات حالياً" />
      </div>
    );
  } else {
    content = displayComments.map((comment, index) => (
      <li key={index} className={classes.BookComments__Comments__Comment}>
        <BookComment comment={comment} />
      </li>
    ));
  }

  return (
    <section className={classes.BookComments}>
      <header className={classes.BookComments__Header}>
        <h2 className={classes.BookComments__Header__Title}>
          <span className={classes.BookComments__Count}>{comments.length}</span>
          تعليقات
        </h2>
      </header>
      <ul className={classes.BookComments__Comments}>{content}</ul>
      {pointer < comments.length && (
        <button
          className={classes.BookComments__ViewMoreBtn}
          onClick={viewMoreCommentsClickedHandler}
        >
          المزيد من التعليقات
        </button>
      )}
    </section>
  );
};

export default BookComments;
