// css
import classes from "./Badge.module.css";
// classnames
import classNames from "classnames";

const Badge = (props) => {
  const { IsCart } = props;
  return (
    <div
      className={classNames(
        IsCart ? classes.IsCart_Badge : classes.Normal_Badge
      )}
    >
      {props.children}
    </div>
  );
};

export default Badge;
