// react
import { useEffect, useState } from "react";
// react redux
import { useSelector } from "react-redux";
// components
import Layout from "../../Layout/Layout";
import AddResourceHeader from "../../UI/AddResourceHeader/AddResourceHeader";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import ConvertModal from "../../UI/Modal/ConvertModal/ConvertModal";
import { ToastContainer, toast } from "react-toastify";

import Select from "react-select";
// services
import { GetBookCategoriesForAddBook, addBook } from "../../../services/books";
// css
import classes from "./AddBook.module.css";
// classnames
import classNames from "classnames";
import Spinner from "../../UI/Spinner/Spinner";

const AddBook = () => {
  // redux
  const user = useSelector((state) => state.auth.user);

  // state
  const [form, setForm] = useState({
    bookTitle: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    writerName: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    referenceNumber: {
      value: "",
      touched: false,
      hasError: false,
      errorMessage: "",
      rules: {},
    },
    ISBN: {
      value: "",
      touched: false,
      hasError: false,
      errorMessage: "",
      rules: {},
    },
    publishDate: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    bookDetails: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    price: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        isPrice: true,
      },
    },
    USDPrice: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        isPrice: true,
      },
    },
    bookCover: {
      value: null,
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        isImage: true,
      },
    },
    book: {
      value: null,
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        isFile: true,
      },
    },
    categories: {
      value: [],
      touched: true,
      hasError: true,
      errorMessage: "",
    },
    isFree: {
      value: false,
      hasError: false,
    },
    inSubscription: {
      value: false,
      hasError: false,
    },
    isAcceptedTerms: {
      value: false,
      hasError: false,
      rules: {
        isRequired: true,
      },
    },
  });
  const [convertModalVisible, setConvertModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [addNewBookLoading, setAddNewBookLoading] = useState(false);
  const [uploadedBookImage, setUploadedBookImage] = useState();
  const [showUploadedBookImage, setShowUploadedBookImage] = useState(true);

  // handlers
  const checkValidity = (type, value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired) {
      if (type === "file" ? !value : value.trim() === "") {
        validity.valid = false;
        validity.message = "هذا الحقل مطلوب";
      }
    }

    if (rules.isPrice) {
      if (validity.valid && !/^(?!(0))\d+(?:[.,]\d+)*$/gm.exec(value)) {
        validity.valid = false;
        validity.message = "من فضلك ادخل سعر صحيح";
      }
    }

    if (rules.isAcceptedTerms) {
      if (validity.valid && value === true) {
        validity.valid = false;
        validity.message = "من فضلك وافق على شروط إستخدام الموقع";
      }
    }

    if (rules.isImage) {
      if (
        validity.valid &&
        value.name.split(".")[value.name.split(".").length - 1] !== "png" &&
        value.name.split(".")[value.name.split(".").length - 1] !== "jpg"
      ) {
        validity.valid = false;
        validity.message = "من فضلك اختر صورة من نوع PNG/JPG";
      }
    }

    if (rules.isFile) {
      if (validity.valid && !/(\.pdf|\.epub)$/i.exec(value.name)) {
        // eslint-disable-next-line
        {
          validity.valid = false;
          validity.message = "من فضلك ارفع ملف في صيغة PDF/EPUB";
        }
      }
    }

    return validity;
  };
  const openConvertModal = () => {
    setConvertModalVisible(true);
  };
  const closeConvertModal = () => {
    setConvertModalVisible(false);
  };

  const uploadedBookImagePreviewHandler = (e) => {
    setUploadedBookImage(URL.createObjectURL(e.target.files[0]));
    setShowUploadedBookImage(false);
  };

  const inputChangedHandler = (event) => {
    const { name, type } = event.target;
    let value;
    if (type === "file") {
      value = event.target.files[0];

      if (name === "bookCover") {
        uploadedBookImagePreviewHandler(event);
        if (value.size > 250000) {
          setForm((prevForm) => ({
            ...prevForm,
            [name]: {
              ...prevForm[name],
              touched: true,
              hasError: true,
              errorMessage: "الحد الاقصی لحجم الملف 200KB",
              errorURL: "https://www.resizepixel.com/reduce-image-in-kb/",
              value,
            },
          }));
          return;
        }
      }
      if (name === "book") {
        if (value.name.split(".")[value.name.split(".").length - 1] == "pdf") {
          if (value.size > 25250000) {
            setForm((prevForm) => ({
              ...prevForm,
              [name]: {
                ...prevForm[name],
                touched: true,
                hasError: true,
                errorMessage: "الحد الاقصی لحجم الملف 25MB",
                value,
              },
            }));
            return;
          }
        } else {
          if (value.size > 15250000) {
            setForm((prevForm) => ({
              ...prevForm,
              [name]: {
                ...prevForm[name],
                touched: true,
                hasError: true,
                errorMessage: "1الحد الاقصی لحجم الملف 5MB",
                value,
              },
            }));
            return;
          }
        }
      }
    } else {
      value = event.target.value;
    }
    const { valid, message } = checkValidity(type, value, form[name].rules);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const categoriesChangedHandler = (selectedCategories) => {
    // validate
    let valid = true;
    if (selectedCategories.length === 0) {
      valid = false;
    }
    // update state
    setForm((prevForm) => ({
      ...prevForm,
      categories: {
        ...prevForm.categories,
        hasError: valid ? false : true,
        errorMessage: valid ? "" : "من فضلك اختر اقسام للكتاب",
        touched: true,
        value: selectedCategories,
      },
    }));
  };

  const isFreeChangedHandler = (event) => {
    const { checked } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      price: {
        ...prevForm.price,
        rules: checked
          ? {}
          : {
              isRequired: true,
              isPrice: true,
            },
        value: checked ? "" : prevForm.price.value,
        hasError: checked ? false : true,
        errorMessage: checked ? "" : "من فضلك ادخل سعر صحيح",
      },
      USDPrice: {
        ...prevForm.USDPrice,
        rules: checked
          ? {}
          : {
              isRequired: true,
              isPrice: true,
            },
        value: checked ? "" : prevForm.USDPrice.value,
        hasError: checked ? false : true,
        errorMessage: checked ? "" : "من فضلك ادخل سعر صحيح",
      },
      isFree: { ...prevForm.isFree, value: checked },
    }));
  };

  const inSubscriptionChangedHandler = (event) => {
    const { checked } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      inSubscription: { ...prevForm.inSubscription, value: checked },
    }));
  };

  const isReadTermsChangedHandler = (event) => {
    const { checked } = event.target;
    // validate
    // update state
    setForm((prevForm) => ({
      ...prevForm,
      isAcceptedTerms: {
        ...prevForm.isAcceptedTerms,
        hasError: checked ? false : true,
        errorMessage: checked ? "" : "من فضلك اختر اقسام للكتاب",
        touched: true,
        value: checked,
      },
    }));
  };

  const isCanSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const addNewBook = async (event) => {
    event.preventDefault();
    // validate
    if (!isCanSubmitted()) {
      return;
    }

    // date
    const data = new FormData();
    data.set("Image", form.bookCover.value);
    data.set("Book", form.book.value);
    data.set("Title", form.bookTitle.value);
    data.set("writerName", form.writerName.value);
    data.set("Description", form.bookDetails.value);
    data.set("WriterId", user.uid);
    data.set("PublishDate", form.publishDate.value);
    data.set("ISBN", form.ISBN.value);
    data.set("RefrenceNumber", form.referenceNumber.value);
    data.set("Price", form.price.value);
    data.set("USDPrice", form.USDPrice.value);
    data.set("Free", form.isFree.value);
    data.set("inSubscription", form.inSubscription.value);
    for (const [index, category] of form.categories.value.entries()) {
      data.set(`Categories[${index}].Id`, category.value);
    }

    setAddNewBookLoading(true);

    try {
      await addBook(data);
      toast.success("تم إضافة الكتاب بنجاح", { position: "bottom-right" });
      window.open("https://bookcloudme.com/", "_self");
    } catch (error) {
      toast.error("حدث خطأ ما اثناء اضافة الكتاب", {
        position: "bottom-right",
      });
    }

    setAddNewBookLoading(false);
  };

  // effects
  useEffect(() => {
    const getCategories = async () => {
      setCategoriesLoading(true);

      try {
        const response = await GetBookCategoriesForAddBook();
        if (Array.isArray(response.data)) {
          setCategories(
            response.data.map((category) => ({
              value: category.id,
              label: category.name,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }

      setCategoriesLoading(false);
    };
    getCategories();
  }, []);

  return (
    <Layout shortHeader>
      {/* <AddResourceHeader title="كتاب جديد" /> */}
      <form onSubmit={addNewBook} className={classes.AddBookForm}>
        {/* bookTitle */}
        <div
          className={classNames(
            classes.inline_half_container,
            classes.input_control_container
          )}
        >
          <div className={classes.inline_half_child}>
            <input
              id="bookTitle"
              type="text"
              name="bookTitle"
              value={form.bookTitle.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="اسم الكتاب"
            />
            {form.bookTitle.touched && form.bookTitle.hasError && (
              <p className="input-control__error">
                {form.bookTitle.errorMessage}
              </p>
            )}
          </div>
          {/* writerName */}
          <div
            className={classNames(
              classes.inline_half_child,
              classes.inline_half_child_right
            )}
          >
            <input
              id="writerName"
              type="text"
              name="writerName"
              value={form.writerName.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="اسم الكاتب"
            />
            {form.writerName.touched && form.writerName.hasError && (
              <p className="input-control__error">
                {form.writerName.errorMessage}
              </p>
            )}
          </div>
        </div>
        {/* referenceNumber */}
        <div
          className={classNames(
            classes.inline_half_container,
            classes.input_control_container
          )}
        >
          <div className={classes.inline_half_child}>
            <input
              id="referenceNumber"
              type="text"
              name="referenceNumber"
              value={form.referenceNumber.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="رقم الإيداع بدار الكتب (إن وجد)"
            />
          </div>
          {/* ISBN */}
          <div
            className={classNames(
              classes.inline_half_child,
              classes.inline_half_child_right
            )}
          >
            <input
              id="ISBN"
              type="text"
              name="ISBN"
              value={form.ISBN.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="الترقيم الدولي ISBN (إن وجد)"
            />
          </div>
        </div>
        {/* date */}
        <div className="input-control">
          <input
            id="publishDate"
            type="date"
            name="publishDate"
            value={form.publishDate.value}
            onChange={inputChangedHandler}
            className="input-control__input"
            placeholder="تاريخ النشر"
          />
          {form.publishDate.touched && form.publishDate.hasError && (
            <p className="input-control__error">
              {form.publishDate.errorMessage}
            </p>
          )}
        </div>
        {/* categories */}
        <div className="input-control">
          <Select
            id="categories"
            options={categories}
            onChange={categoriesChangedHandler}
            isMulti={true}
            isSearchable={true}
            isLoading={categoriesLoading}
            noOptionsMessage={() => "لا توجد اقسام حاليا"}
            closeMenuOnSelect={false}
            className="select"
            placeholder="القسم الخاص بالكتاب"
          />

          {form.categories.touched && form.categories.hasError && (
            <p className="input-control__error">
              {form.categories.errorMessage}
            </p>
          )}
        </div>
        {/* bookDetails */}
        <div className="input-control">
          <textarea
            id="bookDetails"
            name="bookDetails"
            value={form.bookDetails.value}
            onChange={inputChangedHandler}
            className="input-control__input"
            rows={4}
            placeholder="وصف / مقدمة الكتاب"
          />
          {form.bookDetails.touched && form.bookDetails.hasError && (
            <p className="input-control__error">
              {form.bookDetails.errorMessage}
            </p>
          )}
        </div>
        <div
          className={classNames(
            classes.inline_half_container,
            classes.input_control_container
          )}
        >
          {/* EGP Price */}
          <div className={classes.inline_half_child}>
            <input
              id="price"
              name="price"
              type="text"
              value={form.price.value}
              disabled={form.isFree.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="السعر داخل مصر ( بالعملة المصرية)"
            />
            {form.price.touched && form.price.hasError && (
              <p className="input-control__error">{form.price.errorMessage}</p>
            )}
          </div>

          {/* USD Price */}
          <div
            className={classNames(
              classes.inline_half_child,
              classes.inline_half_child_right
            )}
          >
            <input
              id="USDPrice"
              name="USDPrice"
              type="text"
              value={form.USDPrice.value}
              disabled={form.isFree.value}
              onChange={inputChangedHandler}
              className="input-control__input"
              placeholder="السعر بالدولار (خارج مصر)"
            />
            {form.USDPrice.touched && form.USDPrice.hasError && (
              <p className="input-control__error">
                {form.USDPrice.errorMessage}
              </p>
            )}
          </div>
        </div>

        <div
          className={classNames(
            classes.inline_half_container,
            classes.input_control_container
          )}
        >
          {/* bookcloudPlus checkbox */}
          <div className={classes.inline_half_child}>
            <input
              className="checkbox_input"
              id="inSubscription"
              type="checkbox"
              value={form.inSubscription}
              onChange={inSubscriptionChangedHandler}
            />
            <label
              style={{ width: "70%" }}
              htmlFor="inSubscription"
              className={classNames(
                "input-control__label",
                "inline_half_child_right_small"
              )}
            >
              ينضم لبوكلاود بلاس!
            </label>
          </div>

          {/* free checkbox */}
          <div
            className={classNames(
              classes.inline_half_child,
              classes.inline_half_child_right
            )}
          >
            <input
              className="checkbox_input"
              id="isFree"
              type="checkbox"
              value={form.isFree}
              onChange={isFreeChangedHandler}
            />
            <label
              style={{ width: "70%" }}
              htmlFor="isFree"
              className={classNames("input-control__label")}
            >
              مجاني
            </label>
          </div>
        </div>

        {/* book upload and cover */}
        <div className={classes.inline_half_container}>
          {/* book cover div */}
          <div className={classes.uploadedBookImage_container}>
            {showUploadedBookImage && (
              <h6 className={classes.uploadedBookImage_title}>صورة الغلاف</h6>
            )}

            <img
              className={classes.uploadedBookImage}
              src={uploadedBookImage}
            />
          </div>
          {/* book upload */}
          <div className={classes.uploadedBook_container}>
            <div className="input-control">
              <label
                htmlFor="bookCover"
                className={classNames(
                  "input-control__label",
                  "input-control__label--required"
                )}
              >
                ارفع غلاف الكتاب صيغة PNG/JPG
              </label>
              <input
                id="bookCover"
                name="bookCover"
                type="file"
                accept=".png, .jpg"
                onChange={inputChangedHandler}
                className="input-control__input"
              />

              {form.bookCover.touched && form.bookCover.hasError && (
                <p className="input-control__error">
                  {form.bookCover.errorMessage}
                </p>
              )}
            </div>

            <div className="input-control">
              <label
                htmlFor="book"
                className={classNames(
                  "input-control__label",
                  "input-control__label--required"
                )}
              >
                ارفع ملف الكتاب في صيغة PDF/EPUB
              </label>
              <input
                id="book"
                name="book"
                type="file"
                accept=".pdf, .epub"
                onChange={inputChangedHandler}
                className="input-control__input"
              />

              {form.book.touched && form.book.hasError && (
                <p className="input-control__error">{form.book.errorMessage}</p>
              )}
            </div>

            <div className="input-control">
              <div className={classes.AddBookForm__ConvertButton}>
                <PrimaryButton
                  text="حول صيغة الكتاب من word الي Epup"
                  clicked={openConvertModal}
                  rounded
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input-control row">
          <input
            required={true}
            id="isAcceptedterms"
            type="checkbox"
            value={form.isAcceptedTerms}
            onChange={isReadTermsChangedHandler}
          />
          <label
            style={{ width: "70%" }}
            htmlFor="isAcceptedterms"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            اوافق على
            <a
              href="https://bookcloudme.com/terms-and-conditions"
              target="_blank"
            >
              {" "}
              شروط إستخدام{" "}
            </a>
            الموقع
          </label>
        </div>

        <div>
          <PrimaryButton
            type="submit"
            clicked={addNewBook}
            disabled={addNewBookLoading || !isCanSubmitted()}
            text="رفع الكتاب"
          />
        </div>
        {addNewBookLoading && (
          <div className="spinner-parent ">
            <Spinner />
          </div>
        )}
      </form>
      <ConvertModal visible={convertModalVisible} close={closeConvertModal} />
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </Layout>
  );
};

export default AddBook;
