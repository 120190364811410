// react
import { useState, useEffect, useCallback } from "react";
// components
import Layout from "../Layout/Layout";
import AuthorDetails from "./AuthorDetails/AuthorDetails";
import AuthorStats from "./AuthorStats/AuthorStats";
import SubscriptionUserInfo from "./SubscriptionUserInfo/SubscriptionUserInfo";
import Spinner from "../UI/Spinner/Spinner";
import Error from "../UI/Error/Error";
import { ToastContainer } from "react-toastify";
// import Withdraw from "./Withdraw/Withdraw";
// services
import { getUserDetails } from "../../services/account";
import { GetMySubscriptionInfo } from "../../services/subscriptions";

// css
import classes from "./Profile.module.css";

const Profile = () => {
  // state
  const [userDetails, setUserDetails] = useState([]);
  const [userSunscriptionsInfo, setuserSunscriptionsInfo] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState(true);
  const [userDetailsError, setUserDetailsError] = useState("");

  //getDetails
  const getDetails = useCallback(async () => {
    setUserDetailsError("");
    try {
      const response = await getUserDetails();
      setUserDetails(response.data.userDetails);
    } catch (error) {
      setUserDetailsError("حدث خطأ ما اثناء تحميل البيانات");
    }
    setUserDetailsLoading(false);
  }, []);

  // GetMySubscriptionInfoHandler
  const GetMySubscriptionInfoHandler = useCallback(async () => {
    setUserDetailsError("");
    try {
      const subscriptionInforResponse = await GetMySubscriptionInfo();
      setuserSunscriptionsInfo(subscriptionInforResponse.data);
    } catch (error) {
      setUserDetailsError("حدث خطأ ما اثناء تحميل البيانات");
    }
    setUserDetailsLoading(false);
  }, []);

  useEffect(() => {
    getDetails();
    GetMySubscriptionInfoHandler();
  }, [getDetails, GetMySubscriptionInfoHandler]);

  // view
  let content;
  if (userDetailsLoading) {
    content = <Spinner />;
  } else if (userDetailsError) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={userDetailsError} />
      </div>
    );
  } else {
    content = (
      <>
        <AuthorDetails user={userDetails} getDetails={getDetails} />
        <AuthorStats user={userDetails} />
        <SubscriptionUserInfo userInfo={userSunscriptionsInfo} />
        {/* <Withdraw /> */}
        <ToastContainer rtl autoClose={500} closeButton={false} />
      </>
    );
  }
  return (
    <Layout shortHeader>
      <div className={classes.Profile}>{content}</div>
    </Layout>
  );
};

export default Profile;
