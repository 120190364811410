// components
import AuthorBadge from "../../../UI/Badge/AuthorBadge/AuthotBadge";
import Rating from "../../../UI/Rating/Rating";
// css
import classes from "./BookComment.module.css";

const BookComment = (props) => {
  // props
  const { comment } = props;

  return (
    <div className={classes.Comment}>
      <h3>{comment.Title}</h3>
      <div className={classes.Comment__Writer}>
        {/* <div className={classes.Comment__Writer__Badge}>
          <AuthorBadge
            imageSource={comment.ProfilePhoto}
            premium={comment.UserIsVerified}
            smallPremiumIcon
          />
        </div> */}
        <p className={classes.Comment__Writer__Name}>{comment.UserName}</p>
      </div>
      <div className={classes.Comment__Footer}>
        <div>
          <Rating rating={comment.StarCount} onlyStars />
        </div>
        <p className={classes.Comment__Footer__Date}>{comment.Date}</p>
      </div>
      <p className={classes.Comment__Text}>{comment.ReviewContent}</p>
    </div>
  );
};

export default BookComment;
