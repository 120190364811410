// components
import Badge from "../Badge";
// css
import classes from "./PriceBadge.module.css";

const PriceBadge = (props) => {
  const { price, isFree, ISUSDPrice, isCartPage } = props;

  return (
    <Badge>
      <span className={classes.BookPrice}>
        {isFree
          ? "مجاني"
          : ISUSDPrice
          ? `${price} $`
          : !ISUSDPrice
          ? `${price} ج`
          : null}
      </span>
    </Badge>
  );
};

export default PriceBadge;
