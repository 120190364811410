// react
import { useEffect, useState } from "react";
// react router
import { useLocation } from "react-router-dom";
// components
import Ad from "../../Layout/AdSider/Ad/Ad";
import HorizontalBooks from "./HorizontalBooks/HorizontalBooks";
import Spinner from "../../UI/Spinner/Spinner";
import Error from "../../UI/Error/Error";
// constants
import { HORIZONTAL_AD } from "../../../constants/ads";
// services
import {
  getMostReadBooks,
  getMostRecentBooks,
  getBookCategories,
  getBooksByCategoryId,
  getMostRatedBooks,
} from "../../../services/books";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./HorizontalBooksSections.module.css";

const HorizontalBooksSections = (props) => {
  // props
  const { inSunscriptionBook } = props;

  // react router
  const location = useLocation();
  const { pathname } = location;
  // most read books
  const [mostReadBooks, setMostReadBooks] = useState([]);
  const [mostReadBooksLoading, setMostReadBooksLoading] = useState(true);
  const [mostReadBooksError, setMostReadBooksError] = useState("");
  useEffect(() => {
    const getBooks = async () => {
      setMostReadBooksLoading(true);
      setMostReadBooksError("");

      try {
        const response = await getMostReadBooks();
        if (inSunscriptionBook) {
          setMostReadBooks(
            response.data.books.filter(
              (obj) => Boolean(obj.inSubscription) === true
            )
          );
        } else {
          setMostReadBooks(response.data.books);
        }
        debugger;
      } catch (error) {
        console.log(error);
        setMostReadBooksError("حدث خطأ ما أثناء تحميل الكتب");
      }

      setMostReadBooksLoading(false);
    };

    getBooks();
  }, []);

  // most recent books
  const [mostRecentBooks, setMostRecentBooks] = useState([]);
  const [mostRecentBooksLoading, setMostRecentBooksLoading] = useState(true);
  const [mostReacentBooksError, setMostRecentBooksError] = useState("");

  useEffect(() => {
    const getBooks = async () => {
      setMostRecentBooksLoading(true);

      try {
        const response = await getMostRecentBooks();
        if (inSunscriptionBook) {
          setMostRecentBooks(
            response.data.books.filter(
              (obj) => Boolean(obj.inSubscription) === true
            )
          );
        } else {
          setMostRecentBooks(response.data.books);
        }
      } catch (error) {
        console.log(error);
        setMostRecentBooksError("حدث خطأ ما أثناء تحميل الكتب");
      }

      setMostRecentBooksLoading(false);
    };

    getBooks();
  }, []);

  // most rated books
  const [mostRatedBooks, setMostRatedBooks] = useState([]);
  const [mostRatedBooksLoading, setMostRatedBooksLoading] = useState(true);
  const [mostRatedBooksError, setMostRatedBooksError] = useState("");

  useEffect(() => {
    const getBooks = async () => {
      setMostRatedBooksLoading(true);

      try {
        const response = await getMostRatedBooks();
        if (inSunscriptionBook) {
          setMostRatedBooks(
            response.data.books.filter(
              (obj) => Boolean(obj.inSubscription) === true
            )
          );
        } else {
          setMostRatedBooks(response.data.books);
        }
      } catch (error) {
        console.log(error);
        setMostRatedBooksError("حدث خطأ ما أثناء تحميل الكتب");
      }

      setMostRatedBooksLoading(false);
    };

    getBooks();
  }, []);

  // categories
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await getBookCategories();
        setCategories(response.data);
        debugger;
      } catch (error) {
        console.log(error);
      }
    };

    getCategories();
  }, []);

  // categorized books
  const [categorizedBooks, setCategorizedBooks] = useState([]);
  const [categorizedBooksLoading, setCategorizedBooksLoading] = useState(false);
  const [categorizedBooksError, setCategorizedBooksError] = useState("");
  const [categoryPointer, setCategoryPointer] = useState(0);

  const seeMoreClickedHandler = () => {
    setCategoryPointer(categorizedBooks.length);
  };

  useEffect(() => {
    const getCategorizedBooks = async () => {
      const categoriesSlice = categories.slice(
        categoryPointer,
        categoryPointer + 3
      );

      debugger;
      const data = [];
      let isErrorHappened = false;
      setCategorizedBooksLoading(true);
      setCategorizedBooksError("");

      for (const category of categoriesSlice) {
        try {
          const response = await getBooksByCategoryId(category.id);
          const inSunscriptionBookArray = response.data.books.filter(
            (obj) => Boolean(obj.inSubscription) === true
          );
          if (inSunscriptionBook) {
            data.push({
              categoryName: category.name,
              categoryId: category.id,
              books: inSunscriptionBookArray,
            });
          } else {
            data.push({
              categoryName: category.name,
              categoryId: category.id,
              books: response.data.books,
            });
          }
        } catch (error) {
          isErrorHappened = true;
        }
      }
      setCategorizedBooksLoading(false);

      if (!isErrorHappened) {
        setCategorizedBooks((prevState) => [...prevState, ...data]);
        debugger;
      } else {
        setCategorizedBooksError("حدث خطأ ما أثناء تحميل الكتب");
      }
    };

    if (categories.length !== 0) {
      getCategorizedBooks();
    }
  }, [categories, categoryPointer]);

  return (
    <>
      <HorizontalBooks
        title="الاكثر قراءه"
        isLoading={mostReadBooksLoading}
        booksError={mostReadBooksError}
        books={mostReadBooks}
        margin={{ bottom: 111 }}
      />
      <HorizontalBooks
        title="الاحدث اصدارا"
        titleLink="/books?sortBy=2"
        isLoading={mostRecentBooksLoading}
        booksError={mostReacentBooksError}
        books={mostRecentBooks}
        margin={{ bottom: 191 }}
      />
      <HorizontalBooks
        title="الاعلى تقيما"
        titleLink="/books?sortBy=4"
        isLoading={mostRatedBooksLoading}
        booksError={mostRatedBooksError}
        books={mostRatedBooks}
        margin={{ bottom: 191 }}
      />
      <Ad
        margin={{ bottom: 82 }}
        type={HORIZONTAL_AD}
        priority={getAdPriority(pathname)}
      />

      {categorizedBooks.length === 0 && categorizedBooksLoading && (
        <div className="spinner-parent">
          <Spinner />
        </div>
      )}
      {categorizedBooks.length === 0 && categorizedBooksError && (
        <Error errorMessage={categorizedBooksError} />
      )}
      {categorizedBooks.map((category, index) => (
        <HorizontalBooks
          key={index}
          title={category.categoryName}
          titleLink={`/books?categoryId=${category.categoryId}`}
          isLoading={false}
          books={category.books}
          margin={{ bottom: 90 }}
        />
      ))}
      {categorizedBooks.length < categories.length && (
        <button
          className={classes.SeeMoreBtn}
          onClick={seeMoreClickedHandler}
          disabled={categorizedBooksLoading}
        >
          عرض المزيد
        </button>
      )}
      {categorizedBooks.length !== 0 && categorizedBooksLoading && (
        <div className="spinner-parent">
          <Spinner />
        </div>
      )}
      {categorizedBooks.length !== 0 && categorizedBooksError && (
        <Error errorMessage={categorizedBooksError} />
      )}
    </>
  );
};

export default HorizontalBooksSections;
