// css
import classes from "./SiteMap.module.css";
// components
import LogoSection from "./LogoSection/LogoSection";
import UsingBookCloud from "./UsingBookCloud/UsingBookCloud";
import BookCloudPlusFooter from "./BookCloudPlusFooter/BookCloudPlusFooter";
import QuotesFooter from "./QuotesFooter/QuotesFooter";

const SiteMap = () => {
  return (
    <section className={classes.Sitemap}>
      <LogoSection />
      <QuotesFooter />
      <UsingBookCloud />
      <BookCloudPlusFooter />
    </section>
  );
};

export default SiteMap;
