// css
import classes from "./TopNavBar.module.css";
// components
import TopNavigationMenu from "./../TopNavBar/TopNavigationMenu/TopNavigationMenu";
import AuthenticationCorner from "./../NavBar/AuthenticationCorner/AuthenticationCorner";

const TopNavBar = () => {
  return (
    <>
      <div className={classes.topNavBar}>
        <TopNavigationMenu />
        <AuthenticationCorner />
      </div>
    </>
  );
};

export default TopNavBar;
