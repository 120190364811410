const bookSubDirectory = "/Books";

export const getMostReadBooks = `${bookSubDirectory}/GetMostReadBooks`;

export const getMostRecentBooks = `${bookSubDirectory}/GetMostRecentBooks`;

export const getMostRatedBooks = `${bookSubDirectory}/GetMostRatedBooks`;


export const getBookCategories = `${bookSubDirectory}/GetBookCategories`;
export const GetBookCategoriesForAddBook = `${bookSubDirectory}/GetBookCategoriesForAddBook`;

export const getBooksByCategoryId = `${bookSubDirectory}/GetBooksByCategoryId`;

export const getPagedBooksByCategory = `${bookSubDirectory}/GetPagedBooksByCategory`;

export const getBookById = `${bookSubDirectory}/GetBookById`;

export const convertToEpub = `${bookSubDirectory}/ConvertToEpub`;

export const addBook = `${bookSubDirectory}/AddBook`;

export const addBookReview = `${bookSubDirectory}/AddBookReview`;

export const reportBook = `${bookSubDirectory}/ReportBook`;

export const likeBook = `${bookSubDirectory}/LikeBook`;

export const removeFromCart = `${bookSubDirectory}/RemoveCartItem`;

export const purchaseBooks = `${bookSubDirectory}/PurchaseBook`;


export const addToCart = `${bookSubDirectory}/AddBookToCart`;

export const getFavouriteBooks = `${bookSubDirectory}/GetFavouriteBooks`;

export const addOrRemoveFromFavourite = `${bookSubDirectory}/AddRemoveFavourite`;

export const getMyLibrary = `${bookSubDirectory}/GetMyLibrary`;

export const searchForBooksAndAuthors = `${bookSubDirectory}/SearchForBooksAndAuthors`;

export const shareBook = `${bookSubDirectory}/ShareBook`;

export const GetPagedCategoriesWithBooks = `${bookSubDirectory}/GetPagedCategoriesWithBooks`;