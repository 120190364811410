// react router
import { useSearchParams } from "react-router-dom";
// components
import HorizontalBooksSections from "../Books/HorizontalBooksSections/HorizontalBooksSections";
import Layout from "../Layout/Layout";
import HorizontalPosts from "./HorizontalPosts/HorizontalPosts";
import SearchedBooksAndAuthors from "./SearchedBooksAndAuthors/SearchedBooksAndAuthors";
import PromoCode from "../PromoCode/PromoCode";

const Home = () => {
  const [searchParams] = useSearchParams();

  return (
    <Layout>
      <PromoCode />
      {searchParams.get("q") ? (
        <SearchedBooksAndAuthors searchText={searchParams.get("q")} />
      ) : (
        <>
          <HorizontalBooksSections />
        </>
      )}
    </Layout>
  );
};

export default Home;
