// components
import AuthorBadge from "../../UI/Badge/AuthorBadge/AuthotBadge";
// import ShowMoreText from "react-show-more-text";
import dateformat from "dateformat";
// css
import classes from "./AuthorDetails.module.css";

const AuthorDetails = (props) => {
  // props
  const { authorData } = props;

  return (
    <section className={classes.Author}>
      <div className={classes.Author__Details}>
        <div className={classes.Author__Details__Badge}>
          <AuthorBadge
            imageSource={authorData.author.imgUrl}
            premium={authorData.author.isVerified}
          />
        </div>
        <div className={classes.Author__Details__Text}>
          <h6 className={classes.Author__Books__Posts__Count}>
            <span>{authorData.metadata.booksTotalCount} كتاب </span>|
            <span> {authorData.metadata.postsTotalCount} تدوينة</span>
          </h6>
          <h2 className={classes.Author__Details__Text__Name}>
            {authorData.author.Name}
          </h2>
          {/* <p className={classes.Author__Details__Text__Date}>
            {dateformat(authorData.author.joinedDate, "yyyy-mm-dd")}
          </p> */}
          <p className={classes.Author__Details__Text__info}>
            {/* {authorData.author.info} */}
          </p>
        </div>
      </div>

      {/* {author.lastPostContent && (
        <ShowMoreText
          lines={4}
          more="عرض أكتر"
          less="عرض أقل"
          className={classes.Author__About}
          anchorClass="show-more-less-clickable"
        >
          {author.lastPostContent}
        </ShowMoreText>
      )} */}
    </section>
  );
};

export default AuthorDetails;
