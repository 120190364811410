// css
import classes from "./UsingBookCloud.module.css";

const UsingBookCloud = () => {
  return (
    <div className={classes.UsingBookCloud}>
      <h3>كيف استعمل بوكلاود</h3>
      <p>
        اضف الكتاب الذي تريده لمكتبتك بالشراء له او بالاشتراك في بوكلاود بلس
      </p>
      <p>
        بمجرد اضافته تستطيع قراءته من خلال الابليكيشن ـ في مكتبتي ـ المتاح على
        جوجل وابل ستورز
      </p>
    </div>
  );
};

export default UsingBookCloud;
