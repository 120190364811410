// react
import { useState } from "react";
// redux
import { useDispatch } from "react-redux";
import { login } from "../../../store/authSlice";
// react router
import { Link, useNavigate } from "react-router-dom";
// components
import Error from "../../UI/Error/Error";
import Spinner from "../../UI/Spinner/Spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ar from "react-phone-input-2/lang/ar.json";

// services
import { registerUser } from "../../../services/account";
// helpers
import { parseJWT, persisSessionAuth } from "../../../helpers/auth";
// css
import classes from "./RegisterForm.module.css";
// classnames
import classNames from "classnames";

const RegisterForm = () => {
  // state
  const [firstName, setFirstName] = useState({ value: "", errorMessage: "" });
  const [lastName, setLastName] = useState({ value: "", errorMessage: "" });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    errorMessage: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState({ value: "", errorMessage: "" });
  const [password, setPassword] = useState({ value: "", errorMessage: "" });
  const [confirmationPassword, setConfirmationPassword] = useState({
    value: "",
    errorMessage: "",
  });
  const [accountType, setAccountType] = useState("Writer");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formError, setFormError] = useState("");
  // redux
  const dispatch = useDispatch();
  // react router
  const navigate = useNavigate();

  // handlers
  const firstNameChangedHandler = (event) => {
    const { value } = event.target;
    if (value.trim() === "") {
      setFirstName({ value, errorMessage: "من فضلك ادخل الاسم الاول" });
    } else {
      setFirstName({ value, errorMessage: "" });
    }
  };
  const lastNameChangedHandler = (event) => {
    const { value } = event.target;
    if (value.trim() === "") {
      setLastName({ value, errorMessage: "من فضلك ادخل الاسم الاخير" });
    } else {
      setLastName({ value, errorMessage: "" });
    }
  };
  const phoneNumberChangedHandler = (event) => {
    const { phoneNumber } = event;
    console.log(phone);
    const { value } = event.target;
    if (value.trim() === "") {
      setPhoneNumber({ value, errorMessage: "من فضلك ادخل رقم الهاتف" });
    } else {
      setPhoneNumber({ value, errorMessage: "" });
    }
  };
  const emailChangedHandler = (event) => {
    const { value } = event.target;
    if (value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setEmail({ value, errorMessage: "" });
    } else {
      setEmail({ value, errorMessage: "من فضلك ادخل بريد الكتروني صحيح" });
    }
  };
  const passwordChangedHandler = (event) => {
    const { value } = event.target;
    if (value.trim() !== "") {
      if (value.trim().length < 6) {
        setPassword({
          value,
          errorMessage: "كلمة المرور يجب الا تقل عن 6 احرف",
        });
      } else {
        setPassword({ value, errorMessage: "" });
      }
    } else {
      setPassword({ value, errorMessage: "من فضلك ادخل كلمة السر" });
    }
    // for confirmation password
    if (confirmationPassword.value.trim() !== "") {
      if (value === confirmationPassword.value) {
        setConfirmationPassword((prevValue) => ({
          ...prevValue,
          errorMessage: "",
        }));
      } else {
        setConfirmationPassword((prevValue) => ({
          ...prevValue,
          errorMessage: "كلمة السر غير متطابقة",
        }));
      }
    }
  };
  const confirmationPasswordChangedHandler = (event) => {
    const { value } = event.target;
    if (value.trim() !== "" && value === password.value) {
      setConfirmationPassword({ value, errorMessage: "" });
    } else {
      setConfirmationPassword({ value, errorMessage: "كلة السر غير متطابقة" });
    }
  };
  const accountTypeChangedHandler = (event) => {
    const { value } = event.target;
    setAccountType(value);
  };
  const isFormHasError = () => {
    if (
      !firstName.value ||
      firstName.errorMessage ||
      !lastName.value ||
      lastName.errorMessage ||
      !phone ||
      !email.value ||
      email.errorMessage ||
      !password.value ||
      password.errorMessage ||
      !confirmationPassword.value ||
      confirmationPassword.errorMessage ||
      submitLoading
    ) {
      return true;
    } else {
      return false;
    }
  };
  const registerUserHandler = async (event) => {
    event.preventDefault();
    if (isFormHasError()) {
      return;
    }
    // submit
    const data = {
      FirstName: firstName.value,
      LastName: lastName.value,
      PhoneNumber: phone,
      Email: email.value,
      Password: password.value,
      ConfirmPassword: confirmationPassword.value,
      Role: accountType,
    };
    setSubmitLoading(true);
    try {
      const response = await registerUser(data);
      // tokens and user
      const accessToken = response.data.accessToken;
      const user = parseJWT(accessToken);
      persisSessionAuth(accessToken);
      dispatch(login(user));
      navigate("/");
    } catch (error) {
      if (error.response.data.Message) {
        setFormError("هذا الهاتف مسجل بالفعل");
      } else {
        setFormError("حدث خطأ ما برجاء مراجعة البيانات و المحاولة مرة أخري");
      }
    }
    setSubmitLoading(false);
  };

  // view
  return (
    <>
      <form
        className={classes.RegisterForm}
        method="POST"
        onSubmit={registerUserHandler}
      >
        {/* first name */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="firstName"
            className={classes.RegisterForm__InputRow__Label}
          >
            الاسم الاول
          </label>
          <input
            id="firstName"
            type="text"
            value={firstName.value}
            onChange={firstNameChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {firstName.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {firstName.errorMessage}
            </p>
          )}
        </div>
        {/* last name */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="lastName"
            className={classes.RegisterForm__InputRow__Label}
          >
            الاسم الاخير
          </label>
          <input
            id="lastName"
            type="text"
            value={lastName.value}
            onChange={lastNameChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {lastName.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {lastName.errorMessage}
            </p>
          )}
        </div>
        {/* phone number */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="phoneNumber"
            className={classes.RegisterForm__InputRow__Label}
          >
            رقم الهاتف
          </label>
          <PhoneInput
            id="phoneNumberT"
            style={{ direction: "ltr" }}
            inputStyle={{ backgroundColor: "#f0f0f0", borderRadius: "12px" }}
            country={"eg"}
            enableSearch={true}
            value={phone}
            onChange={(phone) => {
              setPhone(phone);
            }}
          />
          <input
            id="phoneNumber"
            type="hidden"
            value={phone}
            onChange={phoneNumberChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {phoneNumber.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {phoneNumber.errorMessage}
            </p>
          )}
        </div>
        {/* email */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="email"
            className={classes.RegisterForm__InputRow__Label}
          >
            الايميل
          </label>
          <input
            id="email"
            type="email"
            value={email.value}
            onChange={emailChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {email.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {email.errorMessage}
            </p>
          )}
        </div>
        {/* password */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="password"
            className={classes.RegisterForm__InputRow__Label}
          >
            الرقم السري
          </label>
          <input
            id="password"
            type="password"
            value={password.value}
            onChange={passwordChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {password.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {password.errorMessage}
            </p>
          )}
        </div>
        {/* confirmation password */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="confirmationPassword"
            className={classes.RegisterForm__InputRow__Label}
          >
            تأكيد الرقم السري
          </label>
          <input
            id="confirmationPassword"
            type="password"
            value={confirmationPassword.value}
            onChange={confirmationPasswordChangedHandler}
            className={classes.RegisterForm__InputRow__Input}
          />
          {confirmationPassword.errorMessage && (
            <p className={classes.RegisterForm__InputRow__Error}>
              {confirmationPassword.errorMessage}
            </p>
          )}
        </div>
        {/* account type */}
        <div className={classes.RegisterForm__InputRow}>
          <label
            htmlFor="accountType"
            className={classes.RegisterForm__InputRow__Label}
          >
            نوع الحساب
          </label>
          <select
            id="accountType"
            value={accountType}
            onChange={accountTypeChangedHandler}
            className={classNames(
              classes.RegisterForm__InputRow__Input,
              classes["RegisterForm__InputRow__Input--Select"]
            )}
          >
            <option value="Writer">
              {`${accountType === "Writer" ? "" : ""} قارئ / كاتب `}
            </option>
          </select>
        </div>

        {formError && (
          <div className={classes.FormError}>
            <Error errorMessage={formError} />
          </div>
        )}

        <button
          className={classes.RegisterForm__Submit}
          disabled={isFormHasError()}
        >
          {submitLoading ? <Spinner /> : "التسجيل"}
        </button>
        <p className={classes.RegisterForm__HasAccountText}>
          لديك حساب بالفعل؟
        </p>
        <Link to="/account/login" className={classes.RegisterForm__LoginLink}>
          تسجيل الدخول
        </Link>
      </form>
    </>
  );
};

export default RegisterForm;
