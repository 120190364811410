// redux
import { useSelector } from "react-redux";
// react-device-detect
import { isMobile } from "react-device-detect";
// react router
import { NavLink } from "react-router-dom";
// constants
import { navItems } from "../../../../../constants/navItems";
// css
import classes from "./NavigationMenu.module.css";
// classnames
import classNames from "classnames";
// components
import BookCloudApps from "./../../../Footer/SiteMap/AppSection/BookCloudApps/BookCloudApps";
//assets
import bookCloudPlus from "../../../../../assets/icons/bookCloudPlus.svg";

const NavigationMenu = () => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (isAuthenticated && navItems.length < 4) {
    navItems.splice(2, 0, { content: "مكتبتي", href: "/myBooks" });
    // navItems.splice(3, 0, { content: "الاقتباسات", href: "/" });
  }
  // In mobile view only merge top header items with second header and make top header disappear
  if (isMobile) {
    navItems.push({ content: "عن بوكّلاود", href: "/about-us" });
    navItems.push({ content: "شروط الاستخدام", href: "/terms-and-conditions" });
    navItems.push({ content: "اسئله و اجوبة", href: "/" });
  }
  return (
    <>
      <nav className={classes.NavMenu}>
        <ul className={classes.NavMenu__List}>
          {navItems.map((navItem, index) => (
            <li key={index} className={classes.NavMenu__List__Item}>
              <NavLink
                to={navItem.href}
                className={({ isActive }) =>
                  classNames(classes.NavMenu__List__Item__Link, {
                    [classes["NavMenu__List__Item__Link--active"]]: isActive,
                  })
                }
              >
                {navItem.content}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <a className={classes.BookCloudPlusIcon} href="/bookCloudPlus">
        <img alt="bookCloudPlus" src={bookCloudPlus} />
      </a>

      <div className={classes.BookCloudAppsIcons}>
        <BookCloudApps />
      </div>
    </>
  );
};

export default NavigationMenu;
