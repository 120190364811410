// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import PlanItem from "../../../PlanItem/PlanItem";

// css
import classes from "./SubscriptionsModal.module.css";

const SubscriptionsModal = (props) => {
  // props
  const { visible, close, plansResponse } = props;

  // handlers
  const closeHandler = () => {
    close();
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible} bigModal>
        {plansResponse && plansResponse.length > 0
          ? plansResponse.map((plan, index) => {
              if (plan.PlanName === "Monthly Subscription") {
                return (
                  <PlanItem
                    key={index}
                    planPrice={plan.WebPrice}
                    Currency={plan.Currency}
                    planId={plan.Id}
                    Monthly
                    MostUsedPlan={plan.MostUsedPlan}
                  />
                );
              } else if (plan.PlanName === "Quarterly Subscription") {
                return (
                  <PlanItem
                    key={index}
                    planPrice={plan.WebPrice}
                    Currency={plan.Currency}
                    planId={plan.Id}
                    Quarterly
                    MostUsedPlan={plan.MostUsedPlan}
                  />
                );
              } else {
                return (
                  <PlanItem
                    key={index}
                    planPrice={plan.WebPrice}
                    Currency={plan.Currency}
                    planId={plan.Id}
                    Yearly
                    MostUsedPlan={plan.MostUsedPlan}
                  />
                );
              }
            })
          : "Loading..."}
      </Modal>
    </>
  );
};

export default SubscriptionsModal;
