// react
import { useEffect, useState } from "react";
// react router
import { useLocation } from "react-router-dom";
// components
import Spinner from "../../UI/Spinner/Spinner";
import Error from "../../UI/Error/Error";
import HorizontalBooks from "../../Books/HorizontalBooksSections/HorizontalBooks/HorizontalBooks";
import HorizontalWriters from "../../HorizontalWriters/HorizontalWriters";
import Ad from "../../Layout/AdSider/Ad/Ad";
// services
import { searchForBooksAndAuthors } from "../../../services/books";
// constans
import { HORIZONTAL_AD } from "../../../constants/ads";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./SearchedBooksAndAuthors.module.css";

const SearchedBooksAndAuthors = (props) => {
  // props
  const { searchText } = props;
  // state
  const [searchResult, setSearchResult] = useState(null);
  const [searchResultLoading, setSearchResultLoading] = useState(true);
  const [searchResultError, setSearchResultError] = useState("");
  // react router
  const location = useLocation();
  const { pathname } = location;

  // effects
  useEffect(() => {
    const getResults = async () => {
      const params = {
        SearchText: searchText,
      };
      setSearchResultLoading(true);
      setSearchResultError("");

      try {
        const response = await searchForBooksAndAuthors(params);
        setSearchResult(response.data);
      } catch (error) {
        setSearchResultError("حدث خطأ ما اثناء البحث عن نتائج");
      }

      setSearchResultLoading(false);
    };
    getResults();
  }, [searchText]);

  // view
  let content;
  if (searchResultLoading) {
    content = <Spinner />;
  } else if (searchResultError) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={searchResultError} />
      </div>
    );
  } else {
    content = (
      <>
        <HorizontalBooks
          margin={{ bottom: 64 }}
          books={searchResult.books}
          title="نتائج البحث عن كتب"
        />
        <Ad
          margin={{ bottom: 82 }}
          type={HORIZONTAL_AD}
          priority={getAdPriority(pathname)}
        />
        <HorizontalWriters
          margin={{ bottom: 100 }}
          writers={searchResult.authors}
          title="نتائج البحث عن كاتب"
        />
      </>
    );
  }

  return <div className={classes.SearchResults}>{content}</div>;
};

export default SearchedBooksAndAuthors;
