import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// react router
import { useLocation, useNavigate } from "react-router-dom";
// css
import classes from "./PlanItem.module.css";

const PlanItem = (props) => {
  //states
  const [planPriceAfterPromo, setPlanPriceAfterPromo] = useState();
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // react router
  const location = useLocation();
  const navigate = useNavigate();
  // props
  const {
    planPrice,
    Currency,
    Monthly,
    Quarterly,
    Yearly,
    planId,
    MostUsedPlan,
  } = props;

  // handlers
  const subscriptionCartHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      if (Monthly) {
        navigate(
          `/subscriptionCart/Monthly/${planPrice}/${Currency}/${planId}`,
          {
            replace: false,
          }
        );
      } else if (Quarterly) {
        navigate(
          `/subscriptionCart/Quarterly/${planPrice}/${Currency}/${planId}`,
          {
            replace: false,
          }
        );
      } else {
        navigate(
          `/subscriptionCart/Yearly/${planPrice}/${Currency}/${planId}`,
          {
            replace: false,
          }
        );
      }
    }
  };

  // effects
  useEffect(() => {
    const discountAmount = planPrice * 0.15;
    const calulatedPlanPriceAfterPromo = planPrice - discountAmount;
    setPlanPriceAfterPromo(calulatedPlanPriceAfterPromo);
  }, [planPrice]);

  return (
    <div
      className={`${
        MostUsedPlan ? classes.Plan_IsMostUsed : classes.Plan_Contanier
      }`}
    >
      <div className={classes.Plan_Container}>
        {MostUsedPlan ? (
          <div className={classes.MostUsedPlan}>الأكثر شهرة</div>
        ) : null}
        <div className={classes.Plan_Title}>
          {Monthly ? (
            <h1>اشتراك شهري</h1>
          ) : Quarterly ? (
            <h1>اشتراك 6 شهور</h1>
          ) : Yearly ? (
            <h1>اشتراك سنوي</h1>
          ) : null}

          <h6>
            <span className={classes.Plan_Price_Right}>{planPrice}</span>
            {Currency === "USD" ? (
              <span>دولار</span>
            ) : Currency === "EGP" ? (
              <span>جنيه</span>
            ) : null}
          </h6>
          <p className={classes.Plan_After_Promo}>
            <span>بعد خصم البروموكود</span>
            <span> {planPriceAfterPromo}</span>
            <span>{" "}</span>
            {Currency === "USD" ? (
              <span>دولار</span>
            ) : Currency === "EGP" ? (
              <span>جنيه</span>
            ) : null}
           
          </p>
        </div>
        <ul>
          <li>
            <span>آلاف الكتب مقابل </span>
            <span className={classes.Margin_Left}>{Currency}</span>
            <span className={classes.Margin_Left}>{planPrice}</span>
            {Monthly ? (
              <span>شهرياً فقط</span>
            ) : Quarterly ? (
              <span>لمدة 6 شهور فقط</span>
            ) : Yearly ? (
              <span>سنويا فقط</span>
            ) : null}
          </li>
          <li>
            تحميل وقراءة كل الكتب بلا حدود بسعر الكتاب الورقي الواحد اقرأ أينما
            كنت دون الحاجة إلى الإنترنت
          </li>
          <li>بإمكانك إلغاء الاشتراك في أي لحظة</li>
        </ul>

        <button className={classes.Plan_Btn} onClick={subscriptionCartHandler}>
          اشترك الان
        </button>
      </div>
    </div>
  );
};

export default PlanItem;
