// assets
import favedIcon from "../../../assets/icons/newHeartRed.svg";

// css
import classes from "./Faved.module.css";

const Faved = () => {
  return <img src={favedIcon} alt="favedIcon" className={classes.favedIcon} />;
};

export default Faved;
