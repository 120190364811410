// react
import { useState, useEffect } from "react";
// react router
import { useParams, useLocation } from "react-router-dom";
// components
import Ad from "../Layout/AdSider/Ad/Ad";
import Layout from "../Layout/Layout";
import BookDetails from "./BookDetails/BookDetails";
import BookComments from "./BookComments/BookComments";
import Spinner from "../UI/Spinner/Spinner";
import Error from "../UI/Error/Error";
import HorizontalBooks from "./../Books/HorizontalBooksSections/HorizontalBooks/HorizontalBooks";

// services
import { getBookById } from "../../services/books";
import { getAuthorDetails } from "../../services/author";
// constants
import { HORIZONTAL_AD } from "../../constants/ads";
// helpers
import { getAdPriority } from "../../helpers/ad";
// css
import classes from "./Book.module.css";

const Book = () => {
  // params
  const { bookId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  // state
  const [authorBooks, setAuthorBooks] = useState(null);

  const [book, setBook] = useState(null);
  const [bookLoading, setBookLoading] = useState(true);
  const [bookError, setBookError] = useState("");

  // effects
  useEffect(() => {
    const getDetails = async () => {
      setBookLoading(true);
      setBookError(false);

      try {
        const response = await getBookById(bookId);
        setBook(response.data.book);

        const params = {
          UserId: response.data.book.writerUserId,
        };
        const getAuthorDetailsResponse = async () => {
          // setLoading(true);

          try {
            const response = await getAuthorDetails(params);
            setAuthorBooks({
              ...response.data.books,
            });
          } catch (error) {
            // setError("حدث خطا ما اثناء تحميل تفاصيل المؤلف");
            console.log(error);
          }

          // setLoading(false);
        };
        getAuthorDetailsResponse();
      } catch (error) {
        console.log(error);
        setBookError("حدث خطأ ما أثناء تحميل تفصايل الكتاب");
      }

      setBookLoading(false);
    };

    if (bookId) {
      getDetails();
    }
  }, [bookId]);

  let content;
  if (bookLoading) {
    content = <Spinner />;
  } else if (bookError) {
    content = (
      <div className="h-v-center w-60">
        <Error errorMessage={bookError} />
      </div>
    );
  } else {
    content = (
      <>
        <BookDetails book={book} />
        <Ad
          type={HORIZONTAL_AD}
          margin={{ bottom: 98 }}
          priority={getAdPriority(pathname)}
        />
        <BookComments comments={book.reviews} />
        <div className={classes.Author_Books_Container}>
          <HorizontalBooks
            title="كتب الكاتب"
            books={authorBooks}
            margin={{ bottom: 111 }}
          />
        </div>
      </>
    );
  }

  return (
    <Layout shortHeader>
      <div className={classes.Book}>{content}</div>
    </Layout>
  );
};

export default Book;
