// css
import classes from "./QuotesFooter.module.css";

const QuotesFooter = () => {
  return (
    <div className={classes.QuotesFooter}>
      <h3>من الاقتباسات</h3>
      <h4 className={classes.writerName}>اسم الكاتب</h4>
      <p>
        بكل تأكيد استحقت هذه الرواية جائزة البوكر العربي وكل الهالة الإعلامية
        التي احاطتها وكل الشهرة التي وصلتها. استمتعت بمطالعتها ......
      </p>
    </div>
  );
};

// make ... after specific number of characters in <p>

export default QuotesFooter;
