const subscriptionSubDirectory = "/Subscriptions";

export const GetCurrentPlansPrices = `${subscriptionSubDirectory}/GetCurrentPlansPrices`;

export const GetPaymentTokenWeb = `${subscriptionSubDirectory}/GetPaymentTokenWeb`;

export const GetMySubscriptionInfo = `${subscriptionSubDirectory}/GetMySubscriptionInfo`;

export const CancelSubscription = `${subscriptionSubDirectory}/CancelSubscription`;

export const GetSubsciptionOverview = `${subscriptionSubDirectory}/GetSubsciptionOverview`;

export const SubscriptionPromoCalculation = `${subscriptionSubDirectory}/SubscriptionPromoCalculation`;
