// react router
import { Link } from "react-router-dom";
// components
import AuthorBadge from "../../../UI/Badge/AuthorBadge/AuthotBadge";
// classes
import classes from "./Post.module.css";

const Post = (props) => {
  // props
  const { post } = props;

  return (
    <article className={classes.Post}>
      <p className={classes.Post__Title} title={post.PostTitle}>
        <Link to={`/posts/${post.postId}`}>{post.PostTitle}</Link>
      </p>
      <div className={classes.Post__Author}>
        {/* <div className={classes.Post__Author__Badge}>
          <AuthorBadge
            imageSource={post.postedByUserImage}
            premium={post.postedByIsVerified}
            smallPremiumIcon
          />
        </div> */}
        <Link
          to={`/authors/${post.postedByUid}`}
          className={classes.Post__Author__Name}
        >
          {post.postedByUserName}
        </Link>
      </div>

      <p className={classes.Post__Content}>{post.postContent}</p>

      {/* <ul className={classes.Post__Stats}>
        <li
          className={classes.Post__Stats__Stat}
        >{`${post.ShareCount} مشاركة`}</li>
        <li
          className={classes.Post__Stats__Stat}
        >{`${post.commentsCount} تعليق`}</li>
        <li
          className={classes.Post__Stats__Stat}
        >{`${post.likesCount} اعجاب`}</li>
      </ul> */}
    </article>
  );
};

export default Post;
