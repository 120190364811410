// react router
import { Link } from "react-router-dom";
// css
import classes from "./PromoCode.module.css";
// assets
import coupon from "../../assets/images/cupon.png";
import maarad_logo from "../../assets/images/ma3rd-logo.png";
import Copy_Icon from "../../assets/icons/copy-icon.svg";

const PromoCode = () => {
  const PromoCode = "BCPlus2345";

  function CopyToClipboard() {
    navigator.clipboard.writeText(PromoCode);
  }

  return (
    <div className={classes.PromoCode_Container}>
      <div className={classes.PromoCode_Coupon_Img}>
        <img alt="coupon" src={coupon} />
      </div>
      <div className={classes.PromoCode_Coupon_Details}>
        <div className={classes.PromoCode_Coupon_Flex}>
          <div>
            <img alt="maarad_logo" src={maarad_logo} />
          </div>
          <div>
            <h3 className={classes.Green_Text}>صالة 2 / جناح c-18</h3>
            <h1 className={classes.Blue_Text}>عرض خلال معرض الكتاب</h1>
            <h3 className={classes.Green_Text}>ينتهي بتاريخ 1/02/2025</h3>
          </div>
        </div>
        <p className={classes.PromoCode_P}>
          استمتع بعرض لمدة شهر بخصم 15٪ على كل باقات
        </p>
        <Link to="/bookCloudPlus" className={classes.bookcloud_Btn}>
          بوكلاود بلس
        </Link>

        <div className={classes.PromoCode_Copy_Container}>
          <p>
            انسخ الكود الحالي: <span>{PromoCode}</span>
          </p>
          <button onClick={CopyToClipboard}>
            <img alt="Copy_Icon" src={Copy_Icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
