// react router
import { useLocation } from "react-router-dom";
// components
import Spinner from "../../UI/Spinner/Spinner";
import Post from "./Post/Post";
import Ad from "../../Layout/AdSider/Ad/Ad";
import Error from "../../UI/Error/Error";
import Pagination from "../../Pagination/Pagination";
import Info from "../../UI/Info/Info";
// constants
import { HORIZONTAL_AD } from "../../../constants/ads";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./PostsGrid.module.css";

const BooksGrid = (props) => {
  // props
  const {
    changePage,
    currentPage,
    totalCount,
    pageSize,
    postsLoading,
    postsError,
    posts,
  } = props;
  // react router
  const location = useLocation();
  const { pathname } = location;

  // view
  let content;
  if (postsLoading) {
    content = <Spinner />;
  } else if (postsError) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={postsError} />
      </div>
    );
  } else if (posts.length !== 0) {
    content = posts.map((post, index) => <Post key={index} post={post} />);
  } else {
    content = (
      <div className="h-v-center w-60 text-center">
        <Info infoMessage="عفوا لا يوجد تدوينات" />
      </div>
    );
  }

  return (
    <div className={classes.PostsGrid}>
      <div className={classes.PostsGrid__Grid}>
        {content}
        {!postsLoading && (
          <div className={classes.PostsGrid__Ad}>
            <Ad
              type={HORIZONTAL_AD}
              margin={{ bottom: 124 }}
              priority={getAdPriority(pathname)}
            />
          </div>
        )}
      </div>
      {!postsLoading && totalCount !== 0 && (
        <Pagination
          total={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          changePage={changePage}
        />
      )}
    </div>
  );
};

export default BooksGrid;
