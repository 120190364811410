// assets
import facebook from "../../../../../assets/icons/facebook.svg";
import instegram from "../../../../../assets/icons/instagram.svg";
import twiiter from "../../../../../assets/icons/twitter.svg";
// css
import classes from "./SocialAccounts.module.css";
// classnames
import classNames from "classnames";

const SocialAccounts = ({ socialStylesForLoginAndRegister }) => {
  return (
    <div
      className={classNames(
        classes.SocialAccounts_Section,
        socialStylesForLoginAndRegister &&
          classes.Social_Styles_For_Login_Register
      )}
    >
      <a
        className={classNames(
          classes.SocialAccounts__Account,
          classes.SocialAccounts__Instagram
        )}
        href="https://instagram.com/bookcloudme"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instegram} alt="instegram account" />
      </a>
      <a
        className={classNames(
          classes.SocialAccounts__Account,
          classes.SocialAccounts__Twitter
        )}
        href="https://twitter.com/Bookcloudme"
        target="_blank"
        rel="noreferrer"
      >
        <img src={twiiter} alt="twitter account" />
      </a>
      <a
        className={classNames(
          classes.SocialAccounts__Account,
          classes.SocialAccounts__Facebook
        )}
        href="https://www.facebook.com/BookCloudme"
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebook} alt="facebook account" />
      </a>
    </div>
  );
};

export default SocialAccounts;
