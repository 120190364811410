// react router
import { Link, useLocation } from "react-router-dom";
// components
import AuthorBadge from "../../UI/Badge/AuthorBadge/AuthotBadge";
import Ad from "../../Layout/AdSider/Ad/Ad";
import PostActions from "./PostActions/PostActions";
import PostStats from "./PostStats/PostStats";
// constants
import { HORIZONTAL_AD } from "../../../constants/ads";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./PostDetails.module.css";
import Info from "../../UI/Info/Info";
import classNames from "classnames";
import Linkify from "linkify-react";

const PostDetails = (props) => {
  // props
  const { post, getPostDetails, isLiked } = props;
  // react router
  const location = useLocation();
  const { pathname } = location;

  return (
    <section className={classes.PostDetails}>
      <>
        <div className={classes.PostDetails__Details}>
          <h2 className={classes.PostDetails__Details__Title}>
            {post.PostTitle}
          </h2>
          <div className={classes.PostDetails__Details__Author}>
            <div className={classes.PostDetails__Details__Author__Badge}>
              <AuthorBadge imageSource={post.postedByUserImage} />
            </div>
            <Link
              to={`/authors/${post.postedByUid}`}
              className={classes.PostDetails__Details__Author__Name}
              target="_blank"
            >
              {post.postedByUserName}
            </Link>
          </div>
        </div>
        <Ad
          type={HORIZONTAL_AD}
          margin={{ bottom: 37 }}
          priority={getAdPriority(pathname)}
        />

    
      
        {post.postContent ? (
          <p className={classes.PostDetails__PostContent}>{
          
            <Linkify>
          {post.postContent}
        </Linkify>
            
            }</p>
        ) : (
          <div
            className={classNames(
              classes.PostDetails__PostContent,
              "text-center w-60 mx-auto"
            )}
          >
            <Info infoMessage="لا يوجد محتوي لهذه التدوينة" />
          </div>
        )}
        <div className={classes.PostDetails__Footer}>
          <PostActions
            postId={post.postId}
            getPostDetails={getPostDetails}
            isLiked={isLiked}
          />
          <PostStats
            shares={post.ShareCount}
            comments={post.commentsCount}
            likes={post.likesCount}
          />
        </div>
      </>
    </section>
  );
};
export default PostDetails;
