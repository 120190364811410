// react
import { useEffect, useState } from "react";
// react router
import { Link } from "react-router-dom";
// classes
import classes from "./BookCloudPage.module.css";
// components
import Layout from "../Layout/Layout";
import SubscriptionsModal from "../UI/Modal/SubscriptionsModal/SubscriptionsModal";
import HorizontalBooks from "../../components/Books/HorizontalBooksSections/HorizontalBooks/HorizontalBooks";
import HorizontalBooksSections from "../Books/HorizontalBooksSections/HorizontalBooksSections";

// services
import { GetCurrentPlansPrices } from "../../services/subscriptions";
import { GetSubsciptionOverview } from "../../services/subscriptions";
import { GetPagedCategoriesWithBooks } from "../../services/books";

const BookCloudPage = (props) => {
  // props
  const {} = props;

  // state
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);

  const [plansPricesResponse, setPlansPricesResponse] = useState([]);

  // intialize variable for object of plan which user subscriped
  const [subscripedUser, setSubscripedUser] = useState({});

  const [subsciptionOverview, setSubsciptionOverview] = useState({});

  // bookcloud books
  const [bookcloudBooksResponse, setBookcloudBooksResponse] = useState([]);

  // handlers
  const subscriptionModalOpenedHandler = async () => {
    setSubscriptionModalVisible(true);
  };

  // subscription Modal
  const subscriptionModalClosedHandler = () => {
    setSubscriptionModalVisible(false);
  };

  useEffect(() => {
    // getCurrentPlansPrices API call
    const getCurrentPlansPricesHandler = async () => {
      var response = await GetCurrentPlansPrices();
      setPlansPricesResponse(response.data.data);
      // get the plan which user subscriped
      setSubscripedUser(
        response.data.data.find((obj) => obj.SubscribedHere === true)
      );
    };

    // GetSubsciptionOverview API call
    const getSubsciptionOverviewHandler = async () => {
      var response = await GetSubsciptionOverview();
      setSubsciptionOverview(response.data.data);
    };

    getCurrentPlansPricesHandler();
    getSubsciptionOverviewHandler();

    return () => {
      console.log("Cleanup on component unmount");
    };
  }, []);

  // view
  return (
    <Layout shortHeader>
      <div className={classes.BookCloudPage}>
        <div className={classes.BookCloudPage__Title_btn_Section}>
          <h1 className={classes.BookCloudPage__Title}>ما هو بوكلاود بلاس</h1>
          {subscripedUser && subscripedUser.length !== 0 ? (
            subscripedUser.PlanName === "Monthly Subscription" ? (
              <div className={classes.Already_Subscriped}>
                <span>أنت مشترك حاليا في باقه</span>{" "}
                <Link to="/profile" className={classes.profile_refer_link}>
                  بوكلاود بلاس الشهريه
                </Link>
              </div>
            ) : subscripedUser.PlanName === "Quarterly Subscription" ? (
              <div className={classes.Already_Subscriped}>
                <span>أنت مشترك حاليا في باقه</span>{" "}
                <Link to="/profile" className={classes.profile_refer_link}>
                  بوكلاود بلاس 6 شهور
                </Link>
              </div>
            ) : (
              <div className={classes.Already_Subscriped}>
                <span>أنت مشترك حاليا في باقه</span>{" "}
                <Link to="/profile" className={classes.profile_refer_link}>
                  بوكلاود بلاس السنوية
                </Link>
              </div>
            )
          ) : (
            <button
              className={classes.BookCloudPage__PlansPricesBtn}
              onClick={subscriptionModalOpenedHandler}
            >
              اعرف الخطط
            </button>
          )}
        </div>
        <h3>{subsciptionOverview.Header}</h3>
        <p>{subsciptionOverview.Segment}</p>
        <h3>{subsciptionOverview.Header2}</h3>
        <p>{subsciptionOverview.unlimitedStr}</p>
        <p>{subsciptionOverview.flexibleStr}</p>
        <p>{subsciptionOverview.economyStr}</p>
        <p>{subsciptionOverview.supportStr}</p>

        <p className={classes.BookCloudPage__Note}>
          <span className={classes.BookCloudPage__asterisk}>*</span>
          {subsciptionOverview.Footer}
        </p>

        <HorizontalBooksSections inSunscriptionBook />

        <SubscriptionsModal
          visible={subscriptionModalVisible}
          close={subscriptionModalClosedHandler}
          plansResponse={plansPricesResponse}
        />
      </div>
    </Layout>
  );
};

export default BookCloudPage;
