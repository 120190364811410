// react router
import { NavLink } from "react-router-dom";
// constants
import { topNavItems } from "../../../../../constants/topNavItems";
// css
import classes from "./TopNavigationMenu.module.css";
// classnames
import classNames from "classnames";

const TopNavigationMenu = () => {
  return (
    <nav className={classes.TopNavMenu}>
      <ul className={classes.TopNavMenu__List}>
        {topNavItems.map((navItem, index) => (
          <li key={index} className={classes.TopNavMenu__List__Item}>
            <NavLink
              to={navItem.href}
              className={({ isActive }) =>
                classNames(classes.TopNavMenu__List__Item__Link, {
                  [classes["TopNavMenu__List__Item__Link--active"]]: isActive,
                })
              }
            >
              {navItem.content}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TopNavigationMenu;
