// react router
import { useLocation } from "react-router-dom";
// components
import Spinner from "../../UI/Spinner/Spinner";
import Book from "../Book/Book";
import Ad from "../../Layout/AdSider/Ad/Ad";
import Error from "../../UI/Error/Error";
// constants
import { HORIZONTAL_AD } from "../../../constants/ads";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./BooksGrid.module.css";
import Pagination from "../../Pagination/Pagination";
import Info from "../../UI/Info/Info";

const BooksGrid = (props) => {
  // props
  const {
    changePage,
    totalCount,
    pageSize,
    currentPage,
    booksLoading,
    books,
    booksError,
  } = props;

  // react router
  const location = useLocation();
  const { pathname } = location;

  // view
  let content;
  if (booksLoading) {
    content = <Spinner />;
  } else if (booksError) {
    content = (
      <div className="h-v-center w-60">
        <Error errorMessage={booksError} />
      </div>
    );
  } else if (books.length !== 0) {
    content = books.map((book) => <Book key={book.id} book={book} />);
  } else {
    content = (
      <div className="h-v-center w-60 text-center">
        <Info infoMessage="عفواً لا توجد كتب حالياً" />
      </div>
    );
  }
  return (
    <div className={classes.BooksGrid}>
      <div className={classes.BooksGrid__Grid}>
        {content}
        <div className={classes.BooksGrid__Ad}>
          {!booksLoading && (
            <Ad
              type={HORIZONTAL_AD}
              margin={{ bottom: 124 }}
              priority={getAdPriority(pathname)}
            />
          )}
        </div>
      </div>
      {!booksLoading && totalCount !== 0 && (
        <Pagination
          total={totalCount}
          pageSize={pageSize}
          changePage={changePage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default BooksGrid;
