// constants
import * as subscriptionsEndPoints from "../constants/endpoints/subscriptions";
// axios
import { axiosPrivateInstance } from "../axios/axios";

export const GetCurrentPlansPrices = () => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.GetCurrentPlansPrices,
    method: "GET",
  });
};

export const GetPaymentTokenWeb = (params) => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.GetPaymentTokenWeb,
    method: "GET",
    params,
  });
};

export const GetMySubscriptionInfo = () => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.GetMySubscriptionInfo,
    method: "GET",
  });
};

export const CancelSubscription = () => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.CancelSubscription,
    method: "POST",
  });
};

export const GetSubsciptionOverview = () => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.GetSubsciptionOverview,
    method: "GET",
    params: { isMobile: false },
  });
};

export const SubscriptionPromoCalculation = (params) => {
  return axiosPrivateInstance({
    url: subscriptionsEndPoints.SubscriptionPromoCalculation,
    method: "GET",
    params,
  });
};
