// react router
import { Link } from "react-router-dom";
// components
import Logo from "../../../../UI/Logo/Logo";
import SocialAccounts from "../SocialAccounts/SocialAccounts";
import AppSection from "../AppSection/AppSection";

// css
import classes from "./LogoSection.module.css";

const LogoSection = () => {
  return (
    <div className={classes.LogoSection}>
      <Link to="/" className={classes.LogoSection__Logo}>
        <Logo />
      </Link>
      <SocialAccounts />
      <AppSection />
    </div>
  );
};

export default LogoSection;
