// react
import { useState } from "react";

// react router
import { Link } from "react-router-dom";

// components
import SubscriptionInfoModal from "./../../UI/Modal/SubscriptionInfoModal/SubscriptionInfoModal";
import { toast } from "react-toastify";

//services
import { CancelSubscription } from "../../../services/subscriptions";

// css
import classes from "./SubscriptionUserInfo.module.css";

const SubscriptionUserInfo = (props) => {
  // props
  const { userInfo } = props;

  //stats
  const [subscriptionInfoModalVisible, setSubscriptionInfoModalVisible] =
    useState(false);

  // subscription modal close
  const subscriptionInfoModalClosedHandler = () => {
    setSubscriptionInfoModalVisible(false);
  };

  // subscriptionInfoModalHandler
  const subscriptionInfoModalHandler = () => {
    setSubscriptionInfoModalVisible(true);
  };

  // CancelSubscriptionHandler
  const cancelSubscriptionHandler = async () => {
    try {
      const response = await CancelSubscription();
      toast.success("تم الغاء الاشتراك", {
        position: "bottom-right",
        autoClose: 2000,
      });
      window.localStorage.setItem("isUserSubscriped", false);
    } catch (error) {
      toast.success("حدث خطا ما اثناء الغاء الاشتراك", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
  };

  return (
    <section className={classes.SubscriptionUserInfo_container}>
      <h1 className={classes.SubscriptionUserInfo_title}>نظام اشتراكك</h1>

      {userInfo.planName === "Monthly Subscription" ? (
        <div className={classes.subInfo_container}>
          <button
            onClick={subscriptionInfoModalHandler}
            className={classes.subInfo}
          >
            <span> بوكلاود بلس | الخطة الشهرية اقرا بلا حدود </span>{" "}
            <span>{userInfo.subscriptionFees} شهريا</span>
          </button>
          <div className={classes.removeSub_container}>
            <button
              className={classes.removeSub}
              onClick={cancelSubscriptionHandler}
            >
              قم بالغاء اشتراكك
            </button>
          </div>
        </div>
      ) : userInfo.planName === "Quarterly Subscription" ? (
        <div className={classes.subInfo_container}>
          <button className={classes.subInfo}>
            <span>بوكلاود بلس | الخطة 6 شهور اقرا بلا حدود</span>{" "}
            <span>{userInfo.subscriptionFees} لمدة 6 شهور</span>
          </button>
          <div className={classes.removeSub_container}>
            <button
              className={classes.removeSub}
              onClick={cancelSubscriptionHandler}
            >
              قم بالغاء اشتراكك
            </button>
          </div>
        </div>
      ) : userInfo.planName === "Yearly Subscription" ? (
        <div className={classes.subInfo_container}>
          <button className={classes.subInfo}>
            <span>بوكلاود بلس | الخطة السنوية اقرا بلا حدود</span>{" "}
            <span>{userInfo.subscriptionFees} سنويا</span>
          </button>
          <div className={classes.removeSub_container}>
            <button
              className={classes.removeSub}
              onClick={cancelSubscriptionHandler}
            >
              قم بالغاء اشتراكك
            </button>
          </div>
        </div>
      ) : !userInfo.planName ? (
        <div>
          <span className={classes.noSub}>أنت غير مشترك في اي نظام حاليا</span>
          <Link to="/bookCloudPlus" className={classes.bookcloud_register}>
            اشترك في بوك كلاود بلس | ما هو!
          </Link>
        </div>
      ) : null}

      <SubscriptionInfoModal
        visible={subscriptionInfoModalVisible}
        close={subscriptionInfoModalClosedHandler}
        userSubscriptionInfo={userInfo}
      />
    </section>
  );
};

export default SubscriptionUserInfo;
