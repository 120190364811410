// css
import classes from "./Modal.module.css";
// classnames
import classNames from "classnames";

const Modal = (props) => {
  // props
  const { visible, children, bigModal } = props;

  return (
    <div
      className={classNames(classes.Modal, classes.bigModal, {
        [classes["Modal--Visible"]]: visible,
        [classes["Big_modal_flex_inline"]]: bigModal,
      })}
    >
      {children}
    </div>
  );
};

export default Modal;
