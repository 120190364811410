// react
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ComponentShow = ({ children }) => {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/account" ||
      location.pathname === "/account/login" ||
      location.pathname === "/account/register" ||
      location.pathname === "/account/password-reset"
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);

  return <div>{showHeader && children}</div>;
};

export default ComponentShow;
