// components
import BookCloudApps from "./BookCloudApps/BookCloudApps";

// css
import classes from "./AppSection.module.css";

const AppSection = ({ socialStylesForLoginAndRegister }) => {
  return (
    <div
      className={
        (classes.AppSection,
        socialStylesForLoginAndRegister &&
          classes.Social_Styles_For_Login_Register)
      }
    >
      <h3 className={classes.AppSection__Header}>حمل التطبيق</h3>
      <BookCloudApps />
    </div>
  );
};

export default AppSection;
