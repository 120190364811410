// react router
import { useNavigate, useLocation } from "react-router-dom";
// redux
import { useSelector, useDispatch } from "react-redux";
import { toggleFavouriteBook } from "../../../../store/favouriteBooksSlice";
// component
import Faved from "../../Faved/Faved";
// services
import { addOrRemoveFromFavourite } from "../../../../services/books";
// react toastify
import { ToastContainer, toast } from "react-toastify";
// assets
import favourite from "../../../../assets/icons/newHeart.svg";
import favouriteWhite from "../../../../assets/icons/newHeartWhite.svg";

// css
import classes from "./FavouriteBadge.module.css";

const FavouriteBadge = (props) => {
  // props
  const { book, whiteIcon } = props;
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const favouriteBooks = useSelector((state) => state.favouriteBooks);
  const currentBookState = favouriteBooks.find(
    (fvBook) => fvBook.id === book.id
  )
    ? true
    : false;
  // react router
  const navigate = useNavigate();
  const location = useLocation();

  // handlers
  const favouriteToggleHandler = async () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: true });
    } else {
      try {
        await addOrRemoveFromFavourite(book.id);
        dispatch(toggleFavouriteBook(book));
        toast.success(
          currentBookState ? "تم الحذف من المفضلة" : "تمت الاضافة الي المفضلة",
          { position: "bottom-right" }
        );
      } catch (error) {
        toast.error("حدث خطأ ما", { position: "bottom-right" });
      }
    }
  };

  return (
    <>
      <span
        role="button"
        className={classes.IconWrapper}
        onClick={favouriteToggleHandler}
      >
        {currentBookState ? (
          <Faved />
        ) : !currentBookState && !whiteIcon ? (
          <img
            src={favourite}
            alt="favourite"
            className={classes.IconWrapper__Icon}
          />
        ) : !currentBookState && whiteIcon ? (
          <img
            src={favouriteWhite}
            alt="favourite"
            className={classes.IconWrapper__Icon}
          />
        ) : null}
      </span>
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </>
  );
};

export default FavouriteBadge;
