// css
import classes from "./BookCloudPlusFooter.module.css";

// react router
import { Link } from "react-router-dom";

const BookCloudPlusFooter = () => {
  return (
    <div className={classes.BookCloudPlusFooter}>
      <h3>بوكلاود بلس</h3>
      <p>اكثر من 1000 كتاب في مقابل 70 جنيه</p>
      <Link to="/bookCloudPlus" className={classes.bookcloud_register_btn}>
        اشترك في بوك كلاود بلس | ما هو!
      </Link>
    </div>
  );
};

export default BookCloudPlusFooter;
