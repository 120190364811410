// react
import { useState } from "react";
// react router dom
import { Link } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// react router
import { useLocation, useNavigate } from "react-router-dom";

// components
import Book from "../../Books/Book/Book";
import AuthorBadge from "../../UI/Badge/AuthorBadge/AuthotBadge";
import BookActions from "./BookActions/BookActions";
import BookStats from "./BookStats/BookStats";
import BookReview from "./BookReview/BookReview";
import Info from "../../UI/Info/Info";
import Rating from "../../UI/Rating/Rating";
import FavouriteBadge from "../../UI/Badge/FavouriteBadge/FavouriteBadge";
import { ToastContainer, toast } from "react-toastify";
import ShareModal from "../../UI/Modal/ShareModal/ShareModal";
import ReportModal from "../../UI/Modal/ReportModal/ReportModal";

// services
import { shareBook } from "../../../services/books";

// css
import classes from "./BookDetails.module.css";
import classNames from "classnames";
import { Helmet } from "react-helmet";

// assets
import share from "../../../assets/icons/newShare.svg";
import reportProblem from "../../../assets/icons/newReportIcon.svg";

const BookDetails = (props) => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // props
  const { book } = props;

  // state
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  // react router
  const navigate = useNavigate();
  const location = useLocation();

  // handlers

  // share
  const shareModalClosedHandler = () => {
    setShareModalVisible(false);
  };
  const bookSharedHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setShareModalVisible(true);
    }
  };
  const bookLinkCopyHandler = async () => {
    navigator.clipboard.writeText(document.URL);
    toast.success("تم نسخ الرابط بنجاح", { position: "bottom-right" });
    await shareBook(book.id);
  };

  // report
  const reportModalClosedHandler = () => {
    setReportModalVisible(false);
  };
  const bookReportedHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setReportModalVisible(true);
    }
  };

  return (
    <section className={classes.BookDetails}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book Details</title>
        <meta property="og:url" content="https://www.bookcloudme.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BookCloud Selfpublishing platform" />
        <meta
          property="og:description"
          content="المنصة الرقمية الاولى في الشرق الأوسط.. بوك كلاود هي المنصة الأنسب لنشر عملك الإبداعي.. فقط بضغطة زر تنشر ابداعاتك وسط الجمهور..ويتواجد كتابك على ارفف مكتبات القراء الالكترونية... معنا ستنشر ابداعاتك وتؤثر فيمن حولك ...فلتكن ”سحاباً يمطر ابداعاً” ... واجعل قرائك يحلقون في سماء ابداعاتك."
        />
        <meta
          property="og:image"
          content="https://media.licdn.com/dms/image/C4D0BAQH9wyjafHoCsg/company-logo_200_200/0/1673901184510?e=1693440000&v=beta&t=chSkAvVBmtu5QStHIRdhGs_OuZzYq2_eGAJWyubz-pA"
        />
      </Helmet>
      <div className={classes.BookDetails__Book}>
        <Book book={book} hideTitle hideAuthorName bookDetailsPageStyle />
      </div>
      <div className={classes.BookDetails__Details}>
        <header className={classes.BookDetails__Details__Header}>
          <div className={classes.BookDetails__Details__Header__text_Container}>
            <ul
              className={classes.BookDetails__Details__Header__Classifications}
            >
              {book.categories.map((category, index) => (
                <li
                  key={index}
                  className={
                    classes.BookDetails__Details__Header__Classifications__Classification
                  }
                >
                  <Link
                    to={`/books?categoryId=${category.CategoryId}`}
                    target="_blank"
                  >
                    {category.CategoryName}
                  </Link>
                </li>
              ))}
            </ul>
            <h2 className={classes.BookDetails__Details__Header__Title}>
              {book.title}
            </h2>
            <div className={classes.BookDetails__Details__Header__Author}>
              {/* <div
                className={classes.BookDetails__Details__Header__Author__Badge}
              >
                <AuthorBadge imageSource={book.writerImageUrl} />
              </div> */}
              <Link
                to={`/authors/${book.writerUserId}`}
                className={classes.BookDetails__Details__Header__Author__Name}
                target="_blank"
              >
                {book.writerUserName}
              </Link>
            </div>
          </div>
          <div
            className={classes.BookDetails__Details__Header__Rating_Container}
          >
            {/* <p className={classes.BookDetails__Details__Header__ReviewText}>
              قيّم الكتاب
            </p> */}
            <Rating
              numberOfRatings={book.numberOfRatings}
              rating={book.rating}
            />

            {/* <BookReview bookId={book.id} /> */}
          </div>
        </header>
        {book.description ? (
          <p className={classes.BookDetails__Details__About}>
            {book.description}
          </p>
        ) : (
          <div
            className={classNames(
              classes.BookDetails__Details__About,
              "text-center w-60 mx-auto"
            )}
          >
            <Info infoMessage="لا يوجد وصف لهذا الكتاب" />
          </div>
        )}
        <div className={classes.Book__Favourite_Share_Container}>
          <span className={classes.Book__Favourite}>
            <FavouriteBadge book={book} />
          </span>
          <span className={classes.BookActions__Action_Share} title="مشاركة">
            <img
              src={share}
              alt="share"
              onClick={bookSharedHandler}
              role="button"
            />
          </span>
        </div>

        <ShareModal
          visible={shareModalVisible}
          close={shareModalClosedHandler}
          onCopyClickHandler={bookLinkCopyHandler}
        />

        <footer className={classes.BookDetails__Details__Footer}>
          {/* <BookActions bookId={book.id} isLiked={book.isLiked} /> */}
          {/* <BookStats
            shares={book.NumOfShares}
            comments={book.numberOfRatings}
            likes={book.NumOfLikes}
          /> */}

          <Link
            to="/bookCloudPlus"
            className={classes.Book_BookCloudPlus_available}
          >
            اشترك في بوك كلاود بلس | ما هو!
          </Link>

          <button
            className={classes.BookActions__Action_Report}
            title="الابلاغ عن مشكلة"
          >
            قدم شكوى
            <img
              className={classes.BookActions__Action_Report_img}
              src={reportProblem}
              alt="report problem"
              onClick={bookReportedHandler}
              role="button"
            />
          </button>

          <ReportModal
            visible={reportModalVisible}
            close={reportModalClosedHandler}
            bookId={book.id}
          />
        </footer>

        <ToastContainer rtl autoClose={500} closeButton={false} />
      </div>
    </section>
  );
};

export default BookDetails;
