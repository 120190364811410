// react
import { useEffect, useState } from "react";
// redux
import { useSelector, useDispatch } from "react-redux";
import { removeItem, emptyCart } from "../../../store/cartSlice";
// components
import { toast } from "react-toastify";
import Spinner from "../../UI/Spinner/Spinner";
// services
import { removeFromCart, purchaseBooks } from "../../../services/books";
// css
import classes from "./CartItems.module.css";
// assets
import removeIcon from "../../../assets/icons/removeIcon.svg";
import { Link } from "react-router-dom";
import { makePayment } from "../../../services/cart";

export const CartItems = () => {
  const [promocodeValue, setPromocodeValue] = useState("");
  let [cartTotalPrice, setCartTotalPrice] = useState();

  // redux
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart);
  // state
  const [purchaseBookLoading, setPurchaseBookLoading] = useState(false);
  const [disablePromoCodeBtn, setDisablePromoCodeBtn] = useState(false);
  const [removeBookLoading, setRemoveBookLoading] = useState(false);

  useEffect(() => {
    // get total price of books in cart once page loading
    setCartTotalPrice(
      cartItems.reduce((accum, current) => current.price + accum, 0)
    );
  }, [cartItems]);

  // handlers
  const cartItemRemovedHandler = async (cartItemId) => {
    const params = {
      id: cartItemId,
    };
    setRemoveBookLoading(true);
    try {
      await removeFromCart(params);
      dispatch(removeItem({ itemId: cartItemId }));
      toast.success("تمت حذف الكتاب من العربة", { position: "bottom-right" });
    } catch (error) {
      toast.error("حدث خطأ ما اثناء حذف الكتاب من العربة", {
        position: "bottom-right",
      });
    }
    setRemoveBookLoading(false);
  };

  const purchaseHandler = async () => {
    setPurchaseBookLoading(true);

    try {
      console.log(
        cartItems.reduce((accum, current) => current.price + accum, 0)
      );
      if (cartTotalPrice > 0) {
        let params;
        // check if promocode btn is disabled which means that promocode is applied, so we will pass the promocode to API
        // else passing empty value
        if (disablePromoCodeBtn) {
          params = {
            PromoCode: "BCPlus2345",
          };
        } else {
          params = {
            PromoCode: "",
          };
        }
        var token = await makePayment(params);
        if (token.data.token != null) {
          window.open(
            "https://accept.paymob.com/api/acceptance/iframes/730880?payment_token=" +
              token.data.token,
            "_self"
          );
        }
      } else {
        await purchaseBooks();
        dispatch(emptyCart());
        toast.success("تمت عملية الشراء بنجاح", { position: "bottom-right" });
      }
    } catch (error) {
      console.log(error);
      toast.error("حدث خطأ ما اثناء عملية الشراء", {
        position: "bottom-right",
      });
    }

    setPurchaseBookLoading(false);
  };

  const promocodeValueHandler = (event) => {
    const { value } = event.target;
    setPromocodeValue(value);
  };

  // apply discount over total price of cart
  const applyPromocodeValueHandler = () => {
    if (promocodeValue === "BCPlus2345") {
      const discountAmount = cartTotalPrice * 0.15;
      cartTotalPrice = cartTotalPrice - discountAmount;
      // update total price of cart after discount
      setCartTotalPrice(cartTotalPrice);
      // disable apply promocode btn
      setDisablePromoCodeBtn(true);
    } else {
    }
  };

  // view
  let content;
  if (cartItems.length === 0) {
    content = (
      <div className={classes.CartItems__Empty}>
        <h2 className={classes.CartItems__Empty__Text}>
          عربة التسوق لديك فارغة
        </h2>
        <Link to="/books" className={classes.CartItems__Empty__Link}>
          تصفح الكتب
        </Link>
      </div>
    );
  } else {
    content = (
      <>
        <table className={classes.CartItems__Table}>
          <thead>
            <tr>
              <th>الكتاب</th>
              <th>السعر</th>
              <th>الحذف</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((cartItem) => (
              <tr key={cartItem.id}>
                <td className={classes.Cover}>
                  <a
                    href={`/books/${cartItem.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={cartItem.coverUrl} alt="book cover" />
                  </a>
                </td>
                <td className={classes.Price}>
                  {`${cartItem.price}`} {cartItem.ISUSDPrice ? `$` : `جنيه`}
                </td>
                <td className={classes.Remove}>
                  {removeBookLoading ? (
                    <Spinner />
                  ) : (
                    <img
                      src={removeIcon}
                      alt="remove"
                      role="button"
                      onClick={() => cartItemRemovedHandler(cartItem.id)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className={classes.PromoCode_Input_Container}>
          <label htmlFor="promocode" className={classes.PromoCode_Input__Label}>
            بروموكود
          </label>
          <input
            className={classes.PromoCode_Input}
            type="text"
            id="promocode"
            value={promocodeValue}
            onChange={promocodeValueHandler}
          />
          <button
            onClick={applyPromocodeValueHandler}
            className={classes.PromoCode_Confirm__Btn}
            disabled={disablePromoCodeBtn}
          >
            تطبيق
          </button>
        </div> */}
        <div className={classes.CartItems__Summary}>
          <p className={classes.CartItems__Summary__Text}>الاجمالي</p>
          <p className={classes.CartItems__Summary__Total}>
            {cartTotalPrice}{" "}
            {cartItems.every((item) => item.ISUSDPrice) ? `$` : `جنيه`}
          </p>
          <button
            className={classes.CartItems__Summary__Complete}
            onClick={purchaseHandler}
            disabled={purchaseBookLoading}
          >
            {purchaseBookLoading ? <Spinner /> : "اتمام الشراء"}
          </button>
        </div>
      </>
    );
  }
  return <div className={classes.CartItems}>{content}</div>;
};

export default CartItems;
