// react
import { useState } from "react";
// react router dom
import { Link } from "react-router-dom";
// css
import classes from "./NavBar.module.css";
// classnames
import classNames from "classnames";
// components
import Logo from "../../../UI/Logo/Logo";
import MobileLogo from "../../../UI/Logo/MobileLogo/MobileLogo";
import NavigationMenu from "./NavigationMenu/NavigationMenu";
import MobileNavigationMenu from "./MobileNavigationMenu/MobileNavigationMenu";

const NavBar = () => {
  // state
  const [openMobileNavigation, setOpenMobileNavigation] = useState(false);
  // handlers
  const toggleMobileNavigationHandler = () => {
    setOpenMobileNavigation((prevOpen) => !prevOpen);
  };
  return (
    <>
      <MobileNavigationMenu open={openMobileNavigation} />
      <div className={classes.Navbar}>
        <button
          className={classes.Navbar__MenuBtn}
          onClick={toggleMobileNavigationHandler}
        >
          <span
            className={classNames(classes.Navbar__MenuBtn__Icon, {
              [classes["Navbar__MenuBtn__Icon--open"]]: openMobileNavigation,
            })}
          ></span>
        </button>
        <Link to="/" className={classes.Navbar__Logo}>
          <Logo />
        </Link>
        <Link to="/" className={classes["Navbar__Logo--mobile"]}>
          <MobileLogo />
        </Link>
        <NavigationMenu />
      </div>
    </>
  );
};

export default NavBar;
