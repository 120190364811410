// react
import { useState } from "react";
// react router dom
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
// css
import classes from "./Search.module.css";
// classnames
import classNames from "classnames";
// assets
import searchIcon from "../../../../assets/icons/NewSearhIcon.svg";

const Search = () => {
  // state
  const [searchText, setSearchText] = useState("");
  // react router
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const searchTextChangedHandler = (e) => {
    if (e.key === "Enter") {
      searchHandler();
      return;
    } else {
      setSearchText(e.target.value);
    }
  };

  const searchHandler = () => {
    if (pathname === "/") {
      searchParams.set("q", searchText);
      setSearchParams(searchParams);
    } else {
      navigate(`/?q=${searchText}`, { replace: false });
    }
  };
  return (
    <div className={classNames(classes.Search)}>
      <input
        type="search"
        className={classes.SearchInput}
        placeholder="قم بالبحث عن كتاب او كاتب"
        onChange={searchTextChangedHandler}
        onKeyDown={searchTextChangedHandler}
      />
      <button className={classes.SearchButton} onClick={searchHandler}>
        <img src={searchIcon} alt="search" />
      </button>
    </div>
  );
};

export default Search;
