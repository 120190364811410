// react
import { useEffect, useRef, useState } from "react";
// hooks
import { useIsFirstRender } from "../../hooks/useIsFirstRender";
// react router
import { useParams } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
import AuthorDetails from "./AuthorDetails/AuthorDetails";
import PostsGrid from "../Posts/PostsGrid/PostsGrid";
import Error from "../UI/Error/Error";
import HorizontalBooks from "./../Books/HorizontalBooksSections/HorizontalBooks/HorizontalBooks";

// services
import { getAuthorDetails } from "../../services/author";
import BooksGrid from "../Books/BooksGrid/BooksGrid";
// css
import classes from "./Author.module.css";
// classnames
import classNames from "classnames";
import Spinner from "../UI/Spinner/Spinner";

const Author = (props) => {
  // props
  const { authorData } = props;
  // params
  const { authorId } = useParams();
  // state
  const [currentGrid, setCurrentGrid] = useState("posts");
  const [author, setAuthor] = useState(null);
  const [books, setBooks] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [booksTotalCount, setBooksTotalCount] = useState(0);
  const [postsTotalCount, setPostsTotalCount] = useState(0);
  // hooks
  const isFirstRender = useIsFirstRender();

  // refs
  const mainSectionRef = useRef();
  // data
  const pageSize = 12;

  // handlers
  const pageChangedHandler = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const gridChangedHandler = (gridName) => {
    setCurrentPage(1);
    setCurrentGrid(gridName);
  };

  // effects
  useEffect(() => {
    const params = {
      UserId: authorId,
      PageNumber: currentPage,
      PageSize: pageSize,
    };
    const getDetails = async () => {
      setLoading(true);

      try {
        const response = await getAuthorDetails(params);
        setAuthor({
          ...response.data,
          lastPostContent: response.data.lastPost?.postContent,
        });
        setBooks(response.data.books);
        setPosts(response.data.posts);
        // setPosts([])
        setBooksTotalCount(response.data.metadata.booksTotalCount);
        setPostsTotalCount(response.data.metadata.postsTotalCount);
      } catch (error) {
        setError("حدث خطا ما اثناء تحميل تفاصيل المؤلف");
        console.log(error);
      }

      setLoading(false);
    };

    if (authorId) {
      getDetails();
    }
  }, [currentGrid, authorId, currentPage]);

  // scroll smoothly
  useEffect(() => {
    if (!isFirstRender) {
      mainSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [currentPage]);

  let content;
  if (loading) {
    content = <Spinner />;
  } else if (error) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={error} />
      </div>
    );
  } else {
    content = (
      <>
        <AuthorDetails authorData={author} />

        <div className={classes.Author_Books_Container}>
          <HorizontalBooks
            title="كتب الكاتب"
            books={author.books}
            margin={{ bottom: 111 }}
          />
        </div>

        <ul className={classes.Tabs}>
          <li
            className={classNames(classes.Tabs__Tab, {
              [classes["Tabs__Tab--active"]]: currentGrid === "posts",
            })}
          >
            <span role="button" onClick={() => gridChangedHandler("posts")}>
              <span className={classes.Posts_Count}>
                {author.metadata.postsTotalCount}
              </span>
              تدوينات
            </span>
          </li>
          {/* <li
            className={classNames(classes.Tabs__Tab, {
              [classes["Tabs__Tab--active"]]: currentGrid === "books",
            })}
          >
            <span role="button" onClick={() => gridChangedHandler("books")}>
              الكتب
            </span>
          </li> */}
        </ul>
        {/* {currentGrid === "books" && (
          <BooksGrid
            books={books}
            changePage={pageChangedHandler}
            currentPage={currentPage}
            totalCount={booksTotalCount}
            pageSize={pageSize}
          />
        )} */}
        {currentGrid === "posts" && (
          <PostsGrid
            posts={posts}
            changePage={pageChangedHandler}
            currentPage={currentPage}
            totalCount={postsTotalCount}
            pageSize={pageSize}
          />
        )}
      </>
    );
  }

  // view
  return (
    <Layout shortHeader>
      <div className={classes.AuthorContainer} ref={mainSectionRef}>
        {content}
      </div>
    </Layout>
  );
};

export default Author;
