// Carousel
import Carousel, { consts } from "@itseasy21/react-elastic-carousel";
// assets
import leftArrow from "../../../assets/icons/leftArrow.svg";
import rightArrow from "../../../assets/icons/rightArrow.svg";
// classnames
import classNames from "classnames";
// css
import classes from "./Slick.module.css";

const Slick = (props) => {
  const { settings, breakPoints } = props;
  const getArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={rightArrow} alt="arrow" />
      ) : (
        <img src={leftArrow} alt="arrow" />
      );
    return (
      <button
        onClick={onClick}
        className={classNames(classes.Arrow, {
          [classes["Arrow--Hidden"]]: isEdge,
        })}
      >
        {pointer}
      </button>
    );
  };

  return (
    <Carousel className={classes.carouselContainer} {...settings} renderArrow={getArrow} breakPoints={breakPoints}>
      {props.children}
    </Carousel>
  );
};

export default Slick;
