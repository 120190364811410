// react
import { useState, useEffect, useRef } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";

// css
import classes from "./SubscriptionInfoModal.module.css";

// classnames
import classNames from "classnames";

const SubscriptionInfoModal = (props) => {
  // props
  const { visible, close, userSubscriptionInfo } = props;

  // handlers
  const closeHandler = () => {
    close();
  };

  useEffect(() => {}, []);

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <div className={classes.SubscriptionInfoModal_container}>
          <div className={classes.subFlex}>
            <span className={classNames(classes.subBold, classes.subFlex_one)}>
              {" "}
              اسم الخطة
            </span>
            {userSubscriptionInfo.planName === "Monthly Subscription" ? (
              <span className={classes.subFlex_one}>خطة شهرية</span>
            ) : userSubscriptionInfo.planName === "Quarterly Subscription" ? (
              <span className={classes.subFlex_one}>خطة لمدة 6 شهور</span>
            ) : userSubscriptionInfo.planName === "Yearly Subscription" ? (
              <span className={classes.subFlex_one}>خطة سنوية</span>
            ) : null}
          </div>
          <div className={classes.subFlex}>
            <span className={classNames(classes.subBold, classes.subFlex_one)}>
              تاريخ بداية الاشتراك
            </span>
            <span className={classes.subFlex_one}>
              {userSubscriptionInfo.subscriptionStartDate}
            </span>
          </div>
          <div className={classes.subFlex}>
            <span className={classNames(classes.subBold, classes.subFlex_one)}>
              تاريخ نهاية الاشتراك
            </span>
            <span className={classes.subFlex_one}>
              {userSubscriptionInfo.subscriptionEndDate}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionInfoModal;
