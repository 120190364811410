// react router
import { Outlet, Link } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
import Logo from "../UI/Logo/Logo";
import SocialAccounts from "../Layout/Footer/SiteMap/SocialAccounts/SocialAccounts";
import AppSection from "../Layout/Footer/SiteMap/AppSection/AppSection";

//assets
import whiteLogo from "../../assets/images/whiteLogo.png";

// css
import classes from "./Account.module.css";

const Account = () => {
  return (
    <Layout noAds navbarOnly>
      <div className={classes.AccountContainer}>
        <div className={classes.registerLoginBG}>
          <div className={classes.AccountContainer__Form}>
            <Outlet />
          </div>
          <div className={classes.leftContainerSocial}>
            <div className={classes.logo_and_social}>
              <Link to="/" className={classes.logo_img}>
                <Logo />
              </Link>
              <SocialAccounts socialStylesForLoginAndRegister />
            </div>
            <div className={classes.Apps_Container}>
              <AppSection socialStylesForLoginAndRegister />
            </div>
          </div>
          <h3 className={classes.Register_CopyRights}>
            جميع الحقوق محفوظة لصالح شركة بوك ان بوكيت 2023
          </h3>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
