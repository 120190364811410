// react
import { useEffect, useState } from "react";
// react router
import { useParams } from "react-router-dom";
// classes
import classes from "./SubscriptionCart.module.css";
// components
import Layout from "../Layout/Layout";
// services
import { SubscriptionPromoCalculation } from "../../services/subscriptions";
import { GetPaymentTokenWeb } from "../../services/subscriptions";

const SubscriptionCart = (props) => {
  //stats
  const [promoValue, setPromoValue] = useState("");
  const [promoCalculation, setPromoCalculation] = useState("");
  const [promocodeErrorMessage, setPromocodeErrorMessage] = useState("");

  // params
  const { planType, planPrice, currency, planId } = useParams();

  const handlePromoInputChange = (e) => {
    setPromoValue(e.target.value); // Update state with the input's value
  };

  // SubscriptionPromoCalculation API call
  const subscriptionPromoCalculationHandler = async (params) => {
    var response = await SubscriptionPromoCalculation(params);
    setPromoCalculation(response.data.data);
  };

  // submitPromocodeHandler fn
  const submitPromocodeHandler = () => {
    const params = {
      PromoCode: promoValue,
      TotalPayment: planPrice,
    };
    // if promocode is not valid
    if (promoValue !== "BCPlus2345") {
      setPromocodeErrorMessage("بروموكود غير صحيح");
      // reset Subscription Promo calculations
      setPromoCalculation("");
    } else {
      subscriptionPromoCalculationHandler(params);
      // if promocode is valid , remove promocode error message
      setPromocodeErrorMessage("");
    }
  };

  const GetPaymentTokenWebHandler = async () => {
    if (promoValue === "BCPlus2345") {
      const params = {
        planId: planId,
        PromoCode: promoValue,
      };
      var planPaymentToken = await GetPaymentTokenWeb(params);
      // var planPaymentToken =
      //   "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5WDJsa0lqb3hNVGd3TXpNMUxDSmhiVzkxYm5SZlkyVnVkSE1pT2pFeU9USXdMQ0pqZFhKeVpXNWplU0k2SWtWSFVDSXNJbWx1ZEdWbmNtRjBhVzl1WDJsa0lqb3pNemcwT0RnMkxDSnZjbVJsY2w5cFpDSTZNall6TWpZeU9ETTNMQ0ppYVd4c2FXNW5YMlJoZEdFaU9uc2labWx5YzNSZmJtRnRaU0k2SWxWelpYSWlMQ0pzWVhOMFgyNWhiV1VpT2lKQlpHMXBiaUlzSW5OMGNtVmxkQ0k2SWs1Qklpd2lZblZwYkdScGJtY2lPaUpPUVNJc0ltWnNiMjl5SWpvaVRrRWlMQ0poY0dGeWRHMWxiblFpT2lKT1FTSXNJbU5wZEhraU9pSk9RU0lzSW5OMFlYUmxJam9pVGtFaUxDSmpiM1Z1ZEhKNUlqb2lUa0VpTENKbGJXRnBiQ0k2SW1Ga2JXbHVRR0ZrYldsdUxtTnZiU0lzSW5Cb2IyNWxYMjUxYldKbGNpSTZJakl3TVRJek5EVTJOemc1TUNJc0luQnZjM1JoYkY5amIyUmxJam9pVGtFaUxDSmxlSFJ5WVY5a1pYTmpjbWx3ZEdsdmJpSTZJazVCSW4wc0lteHZZMnRmYjNKa1pYSmZkMmhsYmw5d1lXbGtJanBtWVd4elpTd2laWGgwY21FaU9udDlMQ0p6YVc1bmJHVmZjR0Y1YldWdWRGOWhkSFJsYlhCMElqcG1ZV3h6WlN3aVpYaHdJam94TnpNeE1qWTNPRFl4TENKd2JXdGZhWEFpT2lJMUxqRTRNaTR4Tnk0eE16VWlmUS44Z0FGWXdJVi13YXZDU2pMZm1XMVg0SHFza3JINGhXQ1E3R1hpY2oyZndCWkNFWjZYeW90WElpNW5SVDdXUHVyckhTcnhTLXlzNDBMY3lpODhURW91dw==";
      if (planPaymentToken.data.data != null) {
        window.open(
          "https://accept.paymob.com/api/acceptance/iframes/730880?payment_token=" +
            planPaymentToken.data.data,
          "_self"
        );
        // window.open(
        //   "https://accept.paymob.com/api/acceptance/iframes/730880?payment_token=" +
        //     planPaymentToken,
        //   "_self"
        // );
      }
    } else if (promoValue === "") {
      setPromocodeErrorMessage("من فضلك ادخل البروموكود");
    }
  };

  // view
  return (
    <Layout shortHeader>
      {planType === "Monthly" ? (
        <h1 className={classes.sub_cart_heading}>خطة بوكلاود بلاس الشهري</h1>
      ) : planType === "Quarterly" ? (
        <h1 className={classes.sub_cart_heading}>خطة بوكلاود بلاس 6 شهور</h1>
      ) : planType === "Yearly" ? (
        <h1 className={classes.sub_cart_heading}>خطة بوكلاود بلاس السنوية </h1>
      ) : null}

      <div className={classes.sub_row_flex}>
        <h3 className={classes.sub_flex_child_one}>المطلوب الاجمالي</h3>
        <p className={classes.sub_flex_child_three}>
          <span>{planPrice}</span>{" "}
          {currency === "USD" ? (
            <span>دولار</span>
          ) : currency === "EGP" ? (
            <span>جنيه</span>
          ) : null}{" "}
          <span>/ الشهر</span>
        </p>
      </div>

      <div className={classes.sub_row_flex}>
        <h3 className={classes.sub_flex_child_one}>ادخل البرومو كود</h3>
        <input
          className={classes.sub_flex_input}
          type="text"
          placeholder="ادخل البرومو كود"
          value={promoValue}
          onChange={handlePromoInputChange}
        />

        <button
          className={classes.sub_submit_promocode}
          onClick={submitPromocodeHandler}
        >
          ارسل
        </button>
      </div>

      <div className={classes.sub_promocode_error_container}>
        <p className={classes.sub_flex_child_one}></p>
        <p className={classes.sub_promocode_error}>{promocodeErrorMessage}</p>
      </div>

      <div className={classes.sub_row_flex}>
        <h3 className={classes.sub_flex_child_one}>توفير البرومو كود</h3>
        <p className={classes.sub_flex_child_three}>
          <span>{promoCalculation.DiscountAmount}</span>{" "}
          {currency === "USD" ? (
            <span>دولار</span>
          ) : currency === "EGP" ? (
            <span>جنيه</span>
          ) : null}{" "}
          <span>/ الشهر</span>
        </p>
      </div>

      <div className={classes.sub_row_flex}>
        <h3 className={classes.sub_flex_child_one}>الاجمالي النهائي</h3>
        <p className={classes.sub_flex_child_three}>
          <span>{promoCalculation.TotalAfterPromo}</span>{" "}
          {currency === "USD" ? (
            <span>دولار</span>
          ) : currency === "EGP" ? (
            <span>جنيه</span>
          ) : null}{" "}
          <span>/ الشهر</span>
        </p>
      </div>

      <div className={classes.sub_submitPayment_container}>
        <button
          className={classes.sub_submitPayment_btn}
          onClick={GetPaymentTokenWebHandler}
        >
          قم بالدفع
        </button>
      </div>
    </Layout>
  );
};

export default SubscriptionCart;
