// css
import NavList from "../NavList/NavList";
import classes from "./CopyBar.module.css";

const CopyBar = () => {
  return (
    <section className={classes.CopyBar}>
      <div className={classes.CopyBar__Content}>
        {/* <div className={classes.CopyBar__Content__Right}>
          <p className="text-center">الحقوق محفوظة ل book n pocket</p>
          <NavList />
        </div>
        <p className={classes.CopyBar__Content__Left}>
          التصميم والبرمجة بمعرفة 
        
          <a target="_blank"
            href="https://www.facebook.com/mostationads"
          >
            Mostation
          </a>
          
        </p> */}
        <p>جميع الحقوق محفوظة لصالح شركة بوك ان بوكيت 2023</p>
      </div>
    </section>
  );
};

export default CopyBar;
