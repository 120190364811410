// react
import { useEffect } from "react";
// components
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ComponentShow from "./../ComponentShow/ComponentShow";
import AdSider from "./AdSider/AdSider";
import FilterSider from "../Books/FilterSider/FilterSider";
// css
import classes from "./Layout.module.css";
// classnames
import classNames from "classnames";

const Layout = (props) => {
  const {
    shortHeader,
    hideSearch,
    showFilter,
    categorySelectedHandler,
    currentSelectedCategoryId,
    noAds,
    navbarOnly,
  } = props;

  // scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <ComponentShow>
        <Header
          shortHeader={shortHeader}
          navbarOnly={navbarOnly}
          hideSearch={hideSearch}
        />
      </ComponentShow>
      <div
        className={classNames(classes.Container, {
          [classes["Container--NoAds"]]: noAds,
        })}
      >
        {!noAds ? (
          showFilter ? (
            <FilterSider
              categorySelectedHandler={categorySelectedHandler}
              currentCategoryId={currentSelectedCategoryId}
            />
          ) : (
            <AdSider />
          )
        ) : null}

        <main
          className={classNames(classes.MainContent, {
            [classes["MainContent--NoAds"]]: noAds,
          })}
        >
          {props.children}
        </main>
        {!noAds && <AdSider />}
      </div>
      <ComponentShow>
        <Footer />
      </ComponentShow>
    </>
  );
};

export default Layout;
