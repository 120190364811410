// react router
import { useNavigate, useLocation } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../../../store/cartSlice";
// services
import { addToCart } from "../../../../services/books";
// components
import Badge from "../Badge";
// react toastify
import { ToastContainer, toast } from "react-toastify";
// assets
import cartIcon from "../../../../assets/icons/newCart-white.svg";
import readingBook from "../../../../assets/icons/readingBook.svg";
// css
import classes from "./CartBadge.module.css";

const CartBadge = (props) => {
  // redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const cart = useSelector((state) => state.cart);
  // react router
  const navigate = useNavigate();
  const location = useLocation();

  // props
  const { book } = props;

  // handlers

  const isInCart = () => {
    if (cart.find((cartItem) => cartItem.id === book.id)) {
      return true;
    }
    return false;
  };
  const cartClickedHandler = async () => {
    var response;
    if (!isAuthenticated) {
      // console.log(isAuthenticated);
      navigate(`/account`, { state: location, replace: true });
    } else {
      try {
        response = await addToCart(book.id);

        dispatch(addItem(book));
        toast.success("تمت الإضافة الي العربة", { position: "bottom-right" });
      } catch (error) {
        //console.log(error);
        //console.log(error.response);
        toast.error(error.response.data.data, { position: "bottom-right" });
      }
    }
  };

  // view
  return (
    <Badge IsCart>
      {isInCart() ? (
        <p className={classes.InCart}>في العربة</p>
      ) : (
        <>
          <span
            role="button"
            className={classes.IconWrapper}
            onClick={cartClickedHandler}
          >
            <img
              src={book.free ? cartIcon : cartIcon}
              alt={book.free ? "reading" : "cart"}
              className={classes.IconWrapper__Icon}
            />
          </span>
          <ToastContainer rtl autoClose={500} closeButton={false} />
        </>
      )}
    </Badge>
  );
};

export default CartBadge;
